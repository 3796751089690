import React from 'react';
import useWindowSize from '@/hooks/useWindowResize.js';
import { useIntl } from "gatsby-plugin-intl";

import Desktop from './Desktop';
import Mobile from './Mobile';

const Extension = ({ id = null }) => {
  const { width } = useWindowSize();
  const { formatMessage } = useIntl();

  const extensionsList = [{
    img_1: 'extension/1-2.png',
    img_2: 'extension/1-1.png',
    text: formatMessage({ id: 'highlights and processes reported tweets with dangerous content' })
  },
  {
    img_1: 'extension/2-1.png',
    img_2: 'extension/2-2.png',
    text: formatMessage({
      id: 'displays status and user`s achievements on Twitter'
    })
  },
  {
    img_1: 'extension/3-1.png',
    img_2: 'extension/3-2.png',
    mobile: 'extension/3--mob.png',
    text: formatMessage({
      id: 'simulates transaction results before blockchain interactions'
    })
  },
  {
    img_1: 'extension/4-2.png',
    img_2: 'extension/4-1.png',
    text: formatMessage({
      id: 'collects real-time data and shows on the project website'
    })
  },
  {
    img_1: 'extension/5-1.png',
    img_2: 'extension/5-2.png',
    text: formatMessage({
      id: 'evaluates project website: verified, scam, fishing and hacked'
    })
  }];

  return <section id={id} className="mt-20 xl:mt-60 pt-24">
    {width > 1536
      ? <Desktop extensionsList={extensionsList} />
      : <Mobile extensionsList={extensionsList} />
    }
  </section>
};

export default Extension;