import React, { useState } from "react";

import { useIntl } from "gatsby-plugin-intl";

import GiffImg from '@/images/mp4.gif';

const GiffButton = () => {
  const { formatMessage } = useIntl();

  const [isBtnState, setBtnState] = useState('btn');

  const showGiff = () => {
    setBtnState('giff');

    setTimeout(() => {
      setBtnState('end');
    }, 5000);
  };

  return (
    <div className="rounded-2xl overflow-hidden  h-[190px]">
      {
        isBtnState === 'btn' &&
        <button type="button" className='flex items-canter py-14 px-5 h-full w-full bg-white text-left transition hover:bg-yellow' onClick={showGiff}>
          <span className="flex items-baseline">
            <span className='flex-shrink-0 mr-3'>
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 2.5H14V0.5H4V2.5H2C0.9 2.5 0 3.4 0 4.5V5.5C0 8.05 1.92 10.13 4.39 10.44C5.02 11.94 6.37 13.07 8 13.4V16.5H4V18.5H14V16.5H10V13.4C11.63 13.07 12.98 11.94 13.61 10.44C16.08 10.13 18 8.05 18 5.5V4.5C18 3.4 17.1 2.5 16 2.5ZM2 5.5V4.5H4V8.32C2.84 7.9 2 6.8 2 5.5ZM16 5.5C16 6.8 15.16 7.9 14 8.32V4.5H16V5.5Z" fill="black" />
              </svg>
            </span>
            <span className="text-2xl font-bold">{formatMessage({ id: 'WOW! Free NFT drop' })}.<br />
              {formatMessage({ id: 'It’s already x1000%' })}<br />
              {formatMessage({ id: 'Mint me' })}!</span>
          </span>
        </button>
      }

      {
        isBtnState === 'giff' &&
        <div class="flex">
          <img src={GiffImg} className="w-full h-full" alt="giff button" class="w-1/2" />
          <img src={GiffImg} className="w-full h-full" alt="giff button" class="w-1/2" />
        </div>
      }

      {
        isBtnState === 'end' &&
        <span className="flex items-center py-14 px-10 h-full text-left bg-yellow">
          <span className="flex items-baseline">
            <span className='flex-shrink-0 mr-3'>
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 2.5H14V0.5H4V2.5H2C0.9 2.5 0 3.4 0 4.5V5.5C0 8.05 1.92 10.13 4.39 10.44C5.02 11.94 6.37 13.07 8 13.4V16.5H4V18.5H14V16.5H10V13.4C11.63 13.07 12.98 11.94 13.61 10.44C16.08 10.13 18 8.05 18 5.5V4.5C18 3.4 17.1 2.5 16 2.5ZM2 5.5V4.5H4V8.32C2.84 7.9 2 6.8 2 5.5ZM16 5.5C16 6.8 15.16 7.9 14 8.32V4.5H16V5.5Z" fill="black" />
              </svg>
            </span>
            <span className="text-2xl font-bold">{formatMessage({
              id: 'Congrats'
            })}!<br />
              {formatMessage({
                id: 'Your balance is 0.0 eth'
              })}.< br />
              {formatMessage({
                id: 'All your NFT gone! LOL'
              })}</span>
          </span>
        </span>
      }
    </div>
  )
};

export default GiffButton;