import React from 'react';
import { useIntl } from "gatsby-plugin-intl";
import DesktopFAQ from './Desktop';
import MobileFAQ from './Mobile';
import useWindowSize from '@/hooks/useWindowResize';

const Faq = ({ id = null }) => {
  const { width } = useWindowSize();
  const { formatMessage } = useIntl();

  const FAQList = [
    {
      title: formatMessage({ id: 'How much should i pay to be safe?' }),
      isSprecials: true
    },
    {
      title: formatMessage({
        id: 'Can statuses be revised?'
      }),
      desc: formatMessage({
        id: `<p>Yes, for example, if your website was hacked but you managed to fix the problems, the hacked status will be removed.</p>`
      })
    },
    {
      title: formatMessage({
        id: 'Is Patrol fast enough?'
      }),
      desc: formatMessage({
        id: `<p>Keeping up with scammers is a difficult challenge, and we believe that SafeSoul’s decentralized community and Patrol members will deliver the fastest practical response time.</p>`
      })
    },
    {
      title: formatMessage({
        id: 'How to get my project verified?'
      }),
      desc: formatMessage({
        id: `<p>The SafeSoul community and Patrol members verify all projects for SafeSoul. Projects and Artists can join SafeSoul for Creators, a paid service, which gives additional tools to pre- verify your project’s assets and manage your response to security events. You can apply for early access today at safesoul.club.</p>`
      })
    },
    {
      title: formatMessage({
        id: 'How big is the SafeSoul Patrol?'
      }),
      desc: formatMessage({
        id: `<p>Patrol size is limited to Patrol bound-token holders, which will be limited to 100,000 tokens.</p>`
      })
    },
    {
      title: formatMessage({
        id: 'What is a Token worth?'
      }),
      desc: formatMessage({
        id: `<p>If a bound token cannot be sold or transferred, how can it be worth anything? In addition to voting rights, token holders can be eligible to receive payment from a reward pool for their Patrol efforts. These payments are underwritten by revenue generated from SafeSoul services like SafeSoul for Creators.</p>`
      })
    },
    {
      title: formatMessage({
        id: 'Why a bound-token for Patrol?'
      }),
      desc: formatMessage({
        id: `<p>Our goal is to keep the verification system fair and make everyone's vote count. A bound token is a non-transferable token, permanently linked to a wallet address, that gives responsibility and power to individuals in the SafeSouls community and supports the “one wallet one vote“ principle.</p>`
      })
    },
    {
      title: formatMessage({
        id: 'How does SafeSoul react to “vote-trolling”'
      }),
      desc: formatMessage({
        id: `<p>SafeSoul accepts votes from token holders on a good-faith basis, but holders who demonstrate a pattern of bad-faith activity, or voting against community safety and interests, can have their voting rights suspended, or be banned altogether. These rules will evolve over time with input from the community.</p>`
      })
    },
    {
      title: formatMessage({
        id: 'How will I earn?'
      }),
      desc: formatMessage({
        id: `<p>The more a token holder contributes to the safety of the community, the larger their portion of SafeSoul’s reward pool for that period. Earning will evolve over time with input from the community.</p>`
      })
    }
  ];

  return <section id={id} className="mt-20 pt-24 pb-14 sm:pb-24">
    <div className="fluid-container">
      <h2 className='mb-6 sm:mb-10 text-6xl sm:text-8xl font-bold'>{formatMessage({
        id: 'Some answers'
      })}
      </h2>

      {
        width > 640
          ? <DesktopFAQ FAQList={FAQList} />
          : <MobileFAQ FAQList={FAQList} />
      }
    </div>
  </section>
}

export default Faq;