import React, { forwardRef } from 'react';

import StaticImg from '@/components/common/StaticImg.jsx';
import GiffButton from './GiffButton';

import { useIntl } from "gatsby-plugin-intl";

const Problem = ({ id = null }, ref) => {
  const { formatMessage } = useIntl();

  return <section id={id} ref={ref} className="mt-20 sm:mt-60 pt-12">
    <div className="overflow-hidden pt-2">
      <div className="fluid-container">
        <h2 className='font-bold text-6xl sm:text-8xl leading-[1em]'>
          <span className='relative'>
            <i className='absolute -left-1 top-1.5 border-[#D62839] border-t-2 border-l-2 h-3 w-3 block'></i>
            <i className='absolute -left-1 bottom-1 border-[#D62839] border-b-2 border-l-2 h-3 w-3 block'></i>
            {formatMessage({ id: "Scam" })}
            <i className='absolute -right-1 top-1.5 border-[#D62839] border-t-2 border-r-2 h-3 w-3 block'></i>
            <i className='absolute -right-1 bottom-1 border-[#D62839] border-b-2 border-r-2 h-3 w-3 block'></i>
          </span> {formatMessage({ id: 'is near' })}</h2>

        <div className="mt-6 sm:mt-10">
          <ul className="flex space-x-1.5 sm:space-x-4 -mx-container overflow-auto sm:overflow-visible px-container sm:px-0 scrollbar-hide sm:scrollbar-default">
            <ul className='space-y-1.5 sm:space-y-4 flex-none w-[300px] sm:w-[390px]'>
              <li>
                <StaticImg path={'problem/1.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/2.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/3.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/4.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/5.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/6.png'} className="w-full" />
              </li>
            </ul>
            <ul className='space-y-1.5 sm:space-y-4 flex-none w-[300px] sm:w-[390px]'>
              <li>
                <StaticImg path={'problem/7.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/8.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/9.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/10.png'} className="w-full" />
              </li>
            </ul>
            <ul className='space-y-1.5 sm:space-y-4 flex-none w-[300px] sm:w-[390px]'>
              <li>
                <StaticImg path={'problem/11.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/12.png'} className="w-full" />
              </li>
              <li className="hidden sm:block">
                <GiffButton />
              </li>
              <li>
                <StaticImg path={'problem/13.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/14.png'} className="w-full" />
              </li>
            </ul>
            <ul className='space-y-1.5 sm:space-y-4 flex-none w-[300px] sm:w-[390px]'>
              <li>
                <StaticImg path={'problem/15.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/16.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/17.png'} className="w-full" />
              </li>
              <li>
                <StaticImg path={'problem/18.png'} className="w-full" />
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  </section>
};

export default forwardRef(Problem);