import React from 'react';

const ButtonShowVideo = ({ openVideo }) => {
  return (
    <button type="button" className="flex items-center justify-center gap-1 rounded-2xl text-sm sm:text-base bg-body-dark/50 backdrop-blur-md text-white font-bold px-1.5 sm:px-3 transition-all hover:bg-body-dark/80 whitespace-nowrap"
      onClick={(e) => {
        e.stopPropagation();
        openVideo();
      }}
    >
      <span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 21a9 9 0 0 0 9-9 9 9 0 0 0-9-9 9 9 0 0 0-9 9 9 9 0 0 0 9 9Zm2.823-9.645-3.882-2.296a.75.75 0 0 0-1.132.646v4.59a.75.75 0 0 0 1.132.647l3.882-2.296a.75.75 0 0 0 0-1.291Z" fill="#fff" />
        </svg>
      </span>

      <span>Watch full video</span>
    </button >
  );
};

export default ButtonShowVideo;