import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { useIntl } from "gatsby-plugin-intl";
import useWindowSize from '@/hooks/useWindowResize.js';

import Desktop from './Desktop';
import Mobile from './Mobile';

const Abilities = ({ id = null, className }, ref) => {
  const { width } = useWindowSize();

  const { formatMessage } = useIntl();


  const headersTabel = [
    {
      title: formatMessage({ id: 'Scout' }),
      description: formatMessage({
        id: 'Any user who has web3 history.'
      })
    },
    {
      title: formatMessage({
        id: 'Patrol'
      }),
      description: formatMessage({
        id: 'Scout with our bound-token.'
      })
    },
    {
      title: formatMessage({
        id: 'OG Patrol'
      }),
      description: formatMessage({
        id: 'Invites only. Most trusted patrol members who are responsible for final decision.'
      }),
      isSpecific: true
    }
  ];

  const rowsTable = [
    {
      title: formatMessage({
        id: 'Extension'
      }),
      description: formatMessage({
        id: 'Free installation of anti-scam plugin in the browser.'
      }),
      checked: [
        1, 1, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Highlighting Twitter scam'
      }),
      description: formatMessage({
        id: 'Alerts appear on dangerous tweets, profiles and projects.'
      }),
      checked: [
        1, 1, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Highlighting dangerous websites'
      }),
      description: formatMessage({
        id: 'Alerts appear on suspicious, fishing, scammed and hacked websites.'
      }),
      checked: [
        1, 1, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Highlighting verification'
      }),
      description: formatMessage({
        id: 'Green badges are given to verified Twitter accounts, websites and projects.'
      }),
      checked: [
        1, 1, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Sending requests to Patrol'
      }),
      description: formatMessage({
        id: 'Notifiyng Patrol about scam, fishing and hacked projects and Twitter profiles.'
      }),
      checked: [
        1, 1, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Suspicious activity review'
      }),
      description: formatMessage({
        id: 'Accept/decline complaints about suspicious activity.'
      }),
      checked: [
        0, 1, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Ads income distribution (DeFi)'
      }),
      description: formatMessage({
        id: 'Income from displaying ads will be distributed among token holders.'
      }),
      checked: [
        0, 1, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Patrol achievements'
      }),
      description: formatMessage({
        id: 'Bound-token holders get badges for being active which are visible on Twitter for SafeSoul users.'
      }),
      checked: [
        0, 1, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Insurance'
      }),
      description: formatMessage({
        id: 'The fund established to aid the community members holding tokens who have been affected by scam activities.'
      }),
      checked: [
        0, 1, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Confirm scam status'
      }),
      description: formatMessage({
        id: 'Accept/decline final status - scam, fishing, hacked or safe source.'
      }),
      checked: [
        0, 0, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Project verification'
      }),
      description: formatMessage({
        id: 'Green badges distribution to applicants who passed SafeSoul safety checks.'
      }),
      checked: [
        0, 0, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Earnings from verification'
      }),
      description: formatMessage({
        id: 'To get a trustworthy green badge, projects can apply for paid verification. Instant application flow generates revenue for verificated members.'
      }),
      checked: [
        0, 0, 1
      ]
    },
    {
      title: formatMessage({
        id: 'Revision of scam status'
      }),
      description: formatMessage({
        id: 'When troubleshooting the status changes.'
      }),
      checked: [
        0, 0, 1
      ]
    },
  ];

  return <section id={id} className={classnames(className, 'mt-10 2xl:-mt-[98vh] pt-20 sm:pt-32 relative z-100 sm:pb-56 bg-body')} ref={ref}>
    {width > 640
      ? <Desktop rowsTable={rowsTable} headersTabel={headersTabel} />
      : <Mobile rowsTable={rowsTable} headersTabel={headersTabel} />
    }
  </section>;
};
export default forwardRef(Abilities);