import React from 'react';
import classnames from 'classnames';
import { useIntl } from "gatsby-plugin-intl";

import Button from '@/components/common/Button';

const poster = '/assets/video/solo.jpg';

const Token = ({ id = null }) => {
  const { formatMessage } = useIntl();

  return (
    <section id={id} className="sm:mt-20 pt-44 sm:pb-10">
      <div className="fluid-container">
        <div className="relative bg-white rounded-2xl p-4 sm:p-10 overflow-hidden">
          <div className="xl:flex items-center">
            <div className="absolute xl:relative -top-20 sm:-top-40 xl:top-auto -right-20 sm:-right-40 xl:right-auto xl:-left-10 2xl:left-auto flex-shrink-0 z-0">
              <div className="">
                <video playsInline autoPlay muted loop className='w-[370px] sm:w-[480px] xl:w-[540px] aspect-square' poster={poster}>
                  <source src="/assets/video/solo.mp4" type="video/mp4" />
                  <source src="/assets/video/solo.webm" type="video/webm" />
                </video>
              </div>
            </div>
            <div className="relative pt-16 sm:pt-0 sm:pr-20 xl:pl-10 2xl:pr-24">
              <h2 className='mb-4 sm:mb-4 text-6xl sm:text-8xl font-bold'>{formatMessage({ id: 'Token' })}</h2>
              <div className="mb-4 space-y-1 sm:space-y-4 text-lg sm:text-3xl font-bold pr-0 max-w-3xl" dangerouslySetInnerHTML={{ __html: formatMessage({ id: '<p>Gives the right to vote in the community of Web3 security experts</p><p>Allows you to receive income from the activities of the project</p><p>Non-transferable after binding,1 per wallet. Confirms the reputation and activity of the expert.</p>' }) }}></div>
              <div className='mt-6 sm:mt-10'>
                <Button to={'#'} className={classnames("!p-3 sm:!py-4 sm:px-5  w-fit bg-[#F8F8F8] pointer-events-none text-black")}>
                  <span className='sm:text-2xl font-bold leading-none'> {formatMessage({ id: 'Read more' })} <span> ({formatMessage({ id: 'soon' })})</span></span>
                  <span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4L10.59 5.41L16.17 11L4 11L4 13L16.17 13L10.59 18.59L12 20L20 12L12 4Z" fill="currentColor" />
                  </svg>
                  </span>
                </Button>
              </div>
            </div>

          </div>
        </div >
      </div >
    </section >
  );
};

export default Token;