import React from 'react';
import StaticImg from '@/components/common/StaticImg.jsx';

import BrowserList from './BrowsersList';

const HowWorks = ({ data }) => {

  return <div className="pt-32">
    <div className="fluid-container">
      <div className="bg-white rounded-2xl p-4 sm:p-8 -mx-[8px]">
        <div className="">
          <div className="mb-6">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M54.35 27.998C54.35 42.552 42.552 54.35 28 54.35c-14.554 0-26.352-11.798-26.352-26.352C1.647 13.445 13.445 1.647 28 1.647c14.553 0 26.351 11.798 26.351 26.351Z" fill="#C2E900" /><path d="M0 0h8.235v1.647H0V0Z" fill="#000" /><path d="M1.647 0v8.235H0V0h1.647ZM56 56h-8.235v-1.648H56V56Z" fill="#000" /><path d="M54.352 56v-8.236H56V56h-1.648ZM0 56v-8.235h1.647V56H0Z" fill="#000" /><path d="M0 54.353h8.235V56H0v-1.647ZM55.999 0v8.235h-1.647V0h1.647Z" fill="#000" /><path d="M55.999 1.647h-8.235V0h8.235v1.647Z" fill="#000" /></svg>
          </div>

          <h2 className="mb-2 font-bold text-6xl sm:text-8xl leading-[1em]">{data.title}</h2>

          <div className="font-bold text-xl space-y-1">{data.text}</div>

          <div className="mt-10">
            <BrowserList />
          </div>
        </div>
      </div>

      <div className="pt-12">
        <div className="flex items-center bg-white rounded-2xl h-[360px] my-10">
          <div className="relative w-full">
            <div className="absolute -translate-y-1/2 top-0 w-full text-center">
              <StaticImg path={data.img} alt="SafeSoul Extension" className="w-full max-w-[370px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default HowWorks;