import React from 'react';
import { useIntl } from "gatsby-plugin-intl";

import StaticImg from '@/components/common/StaticImg.jsx';

const Item = ({ item }) => {
  return (
    <div className="bg-white rounded-3xl xl:rounded-[80px] p-2 sm:p-4 md:p-6 xl:pb-12 h-full group-hover:shadow-lg transition duration-500">
      <div className="mb-2 md:mb-6 max-w-24 sm:max-w-[200px] aspect-square overflow-hidden mx-auto transform-gpu border-squircle">
        <StaticImg path={item.image} alt={item.name} className="h-full w-full object-cover max-w-full" />
      </div>

      <div className="mb-1 sm:mb-1.5 text-sm sm:text-base xl:text-2xl font-bold text-center leading-tight break-words" dangerouslySetInnerHTML={{ __html: item.name }}></div>

      <div className="text-[11px] sm:text-sm xl:text-base font-medium text-black text-center">{item.role}</div>

      {
        item.desc ?
          <div className="mt-1 sm:mt-1.5 text-[11px] sm:text-sm xl:text-base font-medium text-black/40 text-center" dangerouslySetInnerHTML={{ __html: item.desc }}></div>
          : null
      }
    </div>
  )
};

const Team = ({ id }) => {
  const { formatMessage } = useIntl();

  const team = [
    {
      name: 'igor',
      role: formatMessage({
        id: 'teamList.igor.role'
      }),
      desc: formatMessage({
        id: 'teamList.igor.desc'
      }),
      image: 'team/igor.png',
      link: 'https://twitter.com/igor_3000A'
    },
    {
      name: 'SENI',
      role: formatMessage({
        id: 'teamList.seni.role'
      }),
      desc: formatMessage({
        id: 'teamList.seni.desc'
      }),
      image: 'team/seni.png',
      link: 'https://twitter.com/erksenia1'
    },
    {
      name: 'Sasha K',
      role: formatMessage({
        id: 'teamList.sashak.role'
      }),
      desc: formatMessage({
        id: 'teamList.sashak.desc'
      }),
      image: 'team/sasha_k.png',
      link: 'https://twitter.com/Sashacrave'
    },
    {
      name: 'Quickotter',
      role: formatMessage({
        id: 'teamList.Quickotter.role'
      }),
      desc: formatMessage({
        id: 'teamList.Quickotter.desc'
      }),
      image: 'team/quickotter.png',
      link: 'https://twitter.com/quickotter_eth'
    },
    {
      name: 'Marat0ne',
      role: formatMessage({
        id: 'teamList.Quickotter.role'
      }),
      desc: formatMessage({
        id: 'teamList.Quickotter.desc'
      }),
      image: 'team/marat.png',
    },
    {
      name: 'Bharat Krymo',
      role: formatMessage({
        id: 'teamList.BharatKrymo.role'
      }),
      desc: formatMessage({
        id: 'teamList.BharatKrymo.desc'
      }),
      image: 'team/bharat.jpg',
      link: 'https://twitter.com/krybharat'
    },
    {
      name: 'Paddy',
      role: formatMessage({
        id: 'teamList.Paddy.role'
      }),
      desc: formatMessage({
        id: 'teamList.Paddy.desc'
      }),
      image: 'team/paddy.png',
      link: 'https://twitter.com/Paddy_Stash'
    },
    {
      name: 'S0L',
      role: formatMessage({
        id: 'teamList.S0L.role'
      }),
      image: 'team/sol.png',
      link: 'https://twitter.com/CryptoForager81'
    },
    {
      name: 'Ashutosh Barot',
      role: formatMessage({
        id: 'teamList.AshutoshBarot.role'
      }),
      image: 'team/ashutosh.png',
      link: 'https://twitter.com/ashu_barot'
    },
    {
      name: 'Spenc3',
      role: formatMessage({
        id: 'teamList.Spenc3.role'
      }),
      image: 'team/spence-logan.jpeg',
      link: 'https://twitter.com/Spenc3_eth'
    },
    {
      name: 'SonOfLasG',
      role: formatMessage({
        id: 'teamList.SonOfLasG.role'
      }),
      image: 'team/son-lasg.png',
      link: 'https://twitter.com/Sonoflasg'
    },
    {
      name: 'BlackMadShark',
      role: formatMessage({
        id: 'teamList.BlackMadShark.role'
      }),
      image: 'team/BlackMadShark.jpeg',
    },
    {
      name: 'Ian',
      role: formatMessage({
        id: 'teamList.Ian.role'
      }),
      image: 'team/ian.png',
      link: 'https://twitter.com/k0rean_rand0m'
    },
    {
      name: 'Max',
      role: formatMessage({
        id: 'teamList.Max.role'
      }),
      image: 'team/max.png',
    },
    {
      name: 'Pavel',
      role: formatMessage({
        id: 'teamList.Pavel.role'
      }),
      image: 'team/pavel.png',
    },
    {
      name: 'Anastasia',
      role: formatMessage({
        id: 'teamList.Anastasia.role'
      }),
      image: 'team/anastasia.png',
    },
    {
      name: 'Nikita',
      role: formatMessage({
        id: 'teamList.Nikita.role'
      }),
      image: 'team/nikita.png',
    }
  ];

  return (
    <section id={id} className="pt-36 sm:pt-72 sm:pb-20">
      <div className="fluid-container">
        <h2 className='mb-4 sm:mb-4 text-6xl sm:text-8xl font-bold'>{formatMessage({
          id: 'Meet the Team'
        })}</h2>
        <div className="mb-4 sm:mb-8 space-y-1 sm:space-y-2 text-lg sm:text-3xl font-bold max-w-4xl">
          <p>{formatMessage({
            id: 'A close-knit team of enthusiasts from all over the world'
          })}</p>
        </div>

        <ul className='flex flex-wrap -mx-0.5 sm:-mx-2 xl:-mx-4 w-auto'>
          {
            team.map((item, index) => (
              <li className='py-1 px-0.5 sm:p-2 xl:p-4 w-1/3 md:w-1/4 xl:w-1/5' key={index}>
                {
                  item.link ?
                    <a href={item.link} target="_blank" rel="noreferrer" className="block h-full group">
                      <Item item={item} />
                    </a>
                    : <Item item={item} />
                }
              </li>
            ))
          }
        </ul>
      </div>
    </section >
  );
};

export default Team;