import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { useIntl } from "gatsby-plugin-intl";
import StaticImg from '@/components/common/StaticImg.jsx';

const listAchievements = [
  {
    img: '1.png',
    title: '<p>Veteran</p>'
  },
  {
    img: '2.png',
    title: '<p>Bustle</p>'
  },
  {
    img: '3.png',
    title: '<p>Denizen</p>'
  },
  {
    img: '4.png',
    title: '<p>Digger</p>'
  },
  {
    img: '5.png',
    title: '<p>Fortune</p>'
  }
];

const Community = ({ id = null, className }, ref) => {
  const { formatMessage } = useIntl();

  return <section id={id} ref={ref} className={classnames(className, 'bg-gradient-to-b from-white pt-28 overflow-hidden')}>
    <div className='fluid-container'>
      <div className="flex relative sm:pb-20">
        <div className="max-w-2xl relative z-1">
          <h2 className='mb-10 font-bold text-6xl sm:text-8xl leading-[1em] break-words'>{formatMessage({ id: 'Community' })}</h2>

          <div className="space-y-1 sm:space-y-2 text-lg sm:text-3xl font-bold" dangerouslySetInnerHTML={{
            __html: formatMessage({
              id: '<p>Members of the Patrol keep watch and take care of security in web3.</p><p>Active users(who found a suspicious activity on web, spread the word on socials, etc.) get achievements that are visible on Twitter for our community.</p><p>You feel safe in fam with Patrol by your side. Thus, we build a safe community.</p>'
            })
          }}>
          </div>
        </div>

        <div className="flex-1 text-center absolute -top-32 -right-32 xl:static">
          <StaticImg path={'community/box.png'} className="w-[237px] sm:w-auto" />
        </div>
      </div>

      <div className="pt-10 sm:pt-28">
        <div className="flex">
          <div className="hidden xl:block -mt-40">
            <StaticImg path={'community/main.jpg'} className="w-[590px]" />
            {/* <img src="/community/main.jpg" alt="" className='w-[590px]' /> */}
          </div>

          <div className="sm:ml-20">
            <h3 className="mb-6 text-2xl sm:text-4xl font-bold text-black/30">{formatMessage({ id: 'Patrol Achievements' })}</h3>

            <ul className='flex space-x-10 sm:space-x-16'>
              {
                listAchievements.map((item, index) =>
                  <li key={index} className="flex-shrink-0">
                    <div className="mb-3 rounded-3xl overflow-hidden">
                      {/* <img src={`/community/${item.img}`} alt="" className='w-32' /> */}
                      <StaticImg path={`community/${item.img}`} className="w-20 sm:w-32" />
                    </div>
                    <h3 className="text-center sm:text-lg font-medium text-black/60" dangerouslySetInnerHTML={{ __html: item.title }}></h3>
                  </li>)
              }
            </ul>
          </div>
        </div>
      </div>

    </div>
  </section>
}

export default forwardRef(Community);