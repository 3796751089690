import React from 'react';

import { useIntl } from "gatsby-plugin-intl";

const WeTrusted = ({ id }) => {
  const { formatMessage } = useIntl();

  const TRUSTED_PROJECTS = [
    {
      name: formatMessage({ id: "trustedProjects.DA.title" }),
      description: formatMessage({ id: "trustedProjects.DA.text" }),
      image: 'di_animals.svg',
      community: '21K',
      sales: '871 ETH'
    },
    {
      name: formatMessage({ id: "trustedProjects.Artisant.title" }),
      description: formatMessage({ id: "trustedProjects.Artisant.text" }),
      image: 'artisant.svg',
      community: '25K',
      owners: '4 949'
    }
  ];

  return (
    <section id={id} className="sm:mt-32 pt-20 sm:pb-32">
      <div className='fluid-container'>
        <h2 className='mb-4 sm:mb-4 text-6xl sm:text-8xl font-bold'>{formatMessage({ id: 'We are trusted' })}</h2>
        <div className="mb-7 sm:mb-12 max-w-3xl text-lg sm:text-3xl font-bold">
          {formatMessage({ id: 'Projects that entrust us with their security from spam activity in the web3 world' })}
        </div>

        <div className="">
          <ul className="sm:flex -mx-2">
            {
              TRUSTED_PROJECTS.map(item =>
                <li className="md:w-1/2 xl:w-1/4 p-2" key={item.name}>
                  <div className='bg-white rounded-3xl p-6 h-full'>
                    <div className="flex flex-col h-full">
                      <div className="mb-3">
                        <div className="h-24 aspect-square overflow-hidden rounded-xl">
                          <img src={`/images/trusted-projects/${item.image}`} alt={item.name} className="w-full h-full" />
                        </div>
                      </div>

                      <h3 className="mb-2 text-2xl font-bold">
                        {item.name}
                      </h3>

                      <div className='text-black/40 font-medium leading-tight'>
                        {item.description}
                      </div>

                      <div className="mt-auto">
                        <div className="flex mt-10 space-x-6">
                          <div className="">
                            <div className="text-sm text-black/40 font-medium leading-none">{formatMessage({ id: 'Community' })}</div>
                            <div className="font-bold">{item.community}</div>
                          </div>
                          {
                            item.sales &&
                            <div className="">
                              <div className="text-sm text-black/40 font-medium leading-none">{formatMessage({ id: 'Total Sales' })}</div>
                              <div className="font-bold">{item.sales}</div>
                            </div>
                          }
                          {
                            item.owners &&
                            <div className="">
                              <div className="text-sm text-black/40 font-medium leading-none">{formatMessage({ id: 'Owners' })}</div>
                              <div className="font-bold">{item.owners}</div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            }
          </ul>
        </div>
      </div>
    </section>
  );
};

export default WeTrusted;