import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { useIntl } from "gatsby-plugin-intl";

import StaticImg from '@/components/common/StaticImg';

import useWindowSize from '@/hooks/useWindowResize';

const Mobile = ({ extensionsList }) => {
  const { formatMessage } = useIntl();

  const [activeSlide, setActiveSlide] = useState(0);
  const { width, height } = useWindowSize();

  const scrollArea = useRef();

  useEffect(() => {
    if (!scrollArea.current) return;

    const scroll = scrollArea.current;

    const handleScroll = () => {
      [...scroll.children].forEach((item, index) => {
        const position = item.querySelector('.title-element').getBoundingClientRect();

        const { left, right } = position;

        if (left <= (width / 2) && right > width / 2) setActiveSlide(index);
      });
    };

    scroll.addEventListener('scroll', handleScroll);

    return () => scroll.removeEventListener('scroll', handleScroll);
  }, [width]);

  return <div className={classnames('relative pt-20 overflow-hidden')}>
    <div className="fluid-container">
      <h2 className='font-bold text-6xl sm:text-8xl leading-[1em]'>{formatMessage({ id: 'Extension' })}</h2>

      <div className="mt-4 pb-10">
        <div className="mr-auto flex w-fit flex-row-reverse space-x-reverse space-x-3 mb-6">
          {
            [...Array(extensionsList.length).keys()].map((i, index) => (
              <span
                key={index}
                className={classnames(
                  'h-2 rounded-full bg-black transition-[width] duration-200 ease-linear',
                  activeSlide === (extensionsList.length - index - 1) ? 'w-[124px] opacity-100' : 'w-2  opacity-20'
                )}
              ></span>
            ))
          }
        </div>
        <div className="flex overflow-x-auto overflow-y-clip w-screen !-mx-container snap-proximity snap-x portrait:max-h-[calc(100vh_-_200px)]" ref={scrollArea}>
          <div className='flex-shrink-0 px-2 !w-[calc(100vw_-_40px)] sm:!w-[600px] snap-start'>
            <div className='h-full'>
              <h2 className='h-[96px] pl-5 text-2xl font-bold title-element'>{extensionsList[0].text}</h2>
              <div className="relative h-full mt-6">
                <div className="relative w-auto">
                  <StaticImg path="extension/1-mobile.png" className="w-full sm:w-auto h-full" />
                </div>
              </div>

            </div>
          </div>

          <div className='flex-shrink-0 px-2  !w-[calc(100vw_-_40px)] sm:!w-[600px] snap-center'>
            <div className='relative h-full w-full max-w-full'>
              <div className="relative h-full">
                <h2 className='h-[96px] text-2xl font-bold title-element ml-auto'>{extensionsList[4].text}</h2>
                <div className="relative h-full mt-6">
                  <StaticImg path="extension/2-mobile.png" className="w-full sm:w-auto" />
                </div>

              </div>

            </div>
          </div>

          <div className='flex-shrink-0 px-2  !w-[calc(100vw_-_40px)] sm:!w-[600px] snap-center'>
            <div className='relative h-full w-full max-w-full'>
              <div className="relative h-full">
                <h2 className='h-[96px] text-2xl font-bold title-element'>{extensionsList[3].text}</h2>
                <div className="relative h-full mt-6">
                  <StaticImg path="extension/3-mobile.png" className="w-full sm:w-auto" />
                </div>
              </div>

            </div>
          </div>

          <div className='flex-shrink-0 px-2  !w-[calc(100vw_-_40px)] sm:!w-[600px] snap-center'>
            <div className='h-full'>
              <div className="flex-none relative h-full">
                <h2 className='h-[96px] text-2xl font-bold title-element'>{extensionsList[1].text}</h2>
                <div className="relative h-full mt-6">
                  <StaticImg path="extension/4-mobile.png" className="w-full sm:w-auto" />
                </div>
              </div>

            </div>
          </div>

          <div className='flex-shrink-0 px-2 !w-[calc(100vw_-_40px)] sm:!w-[600px] snap-end'>
            <div className='h-full max-w-full'>
              <div className="relative h-full">
                <h2 className='h-[96px] text-2xl font-bold w-[340px] title-element pr-5 '>{extensionsList[2].text}</h2>
                <div className="relative h-full mt-6">
                  <StaticImg path="extension/5-mobile.png" className="w-full sm:w-auto" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Mobile;