import React, { useRef, useEffect, useState, useCallback } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useIntl } from "gatsby-plugin-intl";

import classnames from 'classnames';

import useWindowSize from '@/hooks/useWindowResize.js';

import ItemImg from './ItemImg';

let isInitAnimation = false;

const Desktop = ({ extensionsList }) => {
  const { formatMessage } = useIntl();

  const { width, height } = useWindowSize();
  const refAnim = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const refAnim1 = useRef(null)
  const refAnim2 = useRef(null)
  const refAnim3 = useRef(null)
  const refAnim4 = useRef(null)
  const refAnim5 = useRef(null)

  const updatePaginator = useCallback((progress) => {
    if (!extensionsList) return;

    const slidesEl = extensionsList.length;

    const activeSlideCalc = Math.min(Math.floor(slidesEl * progress), slidesEl - 1);

    setActiveSlide(activeSlideCalc);
  }, [setActiveSlide, extensionsList]);

  useEffect(() => {
    if (!refAnim.current || !refAnim1.current || !refAnim2.current || !refAnim3.current || !refAnim4.current || !refAnim5.current || !width || isInitAnimation) return;

    const q1 = gsap.utils.selector(refAnim1);
    const q2 = gsap.utils.selector(refAnim2);
    const q3 = gsap.utils.selector(refAnim3);
    const q4 = gsap.utils.selector(refAnim4);
    const q5 = gsap.utils.selector(refAnim5);

    let containerAnimation;

    setTimeout(() => {
      // if (isInitAnimation) return;

      const section = refAnim.current;
      if (!section) return;

      const slidesWrapper = section.querySelector('#extension-slides');
      const fullWidth = section.scrollWidth;

      containerAnimation = gsap.to(slidesWrapper, {
        scrollTrigger: {
          trigger: section,
          start: 'bottom bottom',
          end: `+=${fullWidth}`,
          scrub: true,
          pin: true,
          anticipatePin: 1,
          onUpdate: (self) => {
            updatePaginator(self.progress);
          },
        },
        x: -(section.scrollWidth - section.clientWidth * 0.25),
        ease: 'none',
      });

      gsap.from(q1('.animated-scroll'), {
        scrollTrigger: {
          trigger: refAnim1.current,
          start: 'left center',
          end: 'right center',
          horizontal: true,
          scrub: 1,
          containerAnimation: containerAnimation
        },
        ease: 'none',
        x: (i) => i * width * 0.01,
        lazy: false
      });

      gsap.from(q2('.animated-scroll'), {
        scrollTrigger: {
          trigger: refAnim2.current,
          start: 'left center',
          end: 'right center',
          horizontal: true,
          scrub: 1,
          containerAnimation: containerAnimation
        },
        ease: 'none',
        x: (i) => i * width * 0.01,
        lazy: false
      });

      gsap.from(q3('.animated-scroll'), {
        scrollTrigger: {
          trigger: refAnim3.current,
          start: 'left center',
          end: 'right center',
          horizontal: true,
          scrub: 1,
          containerAnimation: containerAnimation
        },
        ease: 'none',
        x: (i) => i * width * 0.01,
        lazy: false
      });

      gsap.from(q4('.animated-scroll'), {
        scrollTrigger: {
          trigger: refAnim4.current,
          start: 'left center',
          end: 'right center',
          horizontal: true,
          scrub: 1,
          containerAnimation: containerAnimation
        },
        ease: 'none',
        x: (i) => i * width * 0.01,
        lazy: false
      });

      gsap.from(q5('.animated-scroll'), {
        scrollTrigger: {
          trigger: refAnim5.current,
          start: 'left center',
          end: 'right center',
          horizontal: true,
          scrub: 1,
          containerAnimation: containerAnimation
        },
        ease: 'none',
        x: (i) => i * width * 0.01,
        lazy: false
      });

      isInitAnimation = true;
    }, 100);

    // return () => {
    //   gsap.killTweensOf(q5('.animated-scroll'));
    //   gsap.killTweensOf(q4('.animated-scroll'));
    //   gsap.killTweensOf(q3('.animated-scroll'));
    //   gsap.killTweensOf(q2('.animated-scroll'));
    //   gsap.killTweensOf(q1('.animated-scroll'));
    //   containerAnimation && containerAnimation.kill();
    // }
  }, [refAnim, refAnim1, refAnim2, refAnim3, refAnim4, refAnim5, width, updatePaginator]);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [width]);

  useEffect(() => {
    return () => {
      isInitAnimation = false;
    }
  }, []);

  return <div className={classnames('relative after:absolute after:bottom-0 after:left-0 after:right-0 after:h-80 after:bg-gradient-to-b after:from-white/0 after:to-white/100 after:-z-1 overflow-hidden')}>

    <div className="fluid-container" ref={refAnim}>
      <h2 className='font-bold text-8xl leading-[1em]'>{formatMessage({ id: 'Extension' })}</h2>

      <div className="mt-4 xtall:mt-6">
        <div className="mr-auto flex w-fit flex-row-reverse space-x-reverse space-x-3 mb-10">
          {
            [...Array(extensionsList.length).keys()].map((i, index) => (
              <span
                key={index}
                className={classnames(
                  'h-2 rounded-full bg-black transition-all duration-200 ease-linear',
                  activeSlide === (extensionsList.length - index - 1) ? 'w-[124px] opacity-100' : 'w-2  opacity-20'
                )}
              ></span>
            ))
          }
        </div>

        <ul className={classnames(
          'flex space-x-20 2xl:space-x-80'
        )}
          style={{ height: height - 250 + 'px' }}
          id="extension-slides"
        >
          <li ref={refAnim1} className='flex flex-none h-full pt-20 2xtall:pt-[132px] w-[75vw] max-w-[1900px]'>
            <div className="flex-none relative h-full aspect-[4/3] -mr-60">
              <div className="absolute left-0 top-0 bottom-0 w-full">
                <ItemImg className={'absolute left-0 bottom-8 w-[69%] animated-scroll'} img={extensionsList[0].img_2} />

                <ItemImg
                  className={'absolute left-[44%] bottom-0 w-[80%] animated-scroll'} img={extensionsList[0].img_1} />
              </div>
            </div>

            <h2 className='text-3xl font-bold animated-scroll max-w-sm -my-10'>{extensionsList[0].text}</h2>
          </li>

          <li ref={refAnim5} className='relative flex flex-none h-full pt-20 2xtall:pt-[132px] w-[75vw] max-w-[1900px]'>
            <div className="flex-none relative h-full aspect-[1.3/1]">
              <div className="absolute left-0 top-0 bottom-0 w-full">
                <ItemImg className={'absolute -left-[4%] bottom-0 w-[60%] animated-scroll'} img={extensionsList[4].img_1} />

                <ItemImg className={'absolute left-[49%] top-0 w-[36%] animated-scroll'} img={extensionsList[4].img_2} />
              </div>

            </div>

            <h2 className='-ml-14 -mr-40 mt-20 text-3xl font-bold animated-scroll max-w-sm'>{extensionsList[4].text}</h2>
          </li>

          <li ref={refAnim4} id='animate4' className='relative flex flex-none h-full pt-20 2xtall:pt-[132px] w-[75vw] max-w-[1900px]' >
            <div className="flex-none relative h-full aspect-[1.82/1] -mr-32">
              <div className="absolute -left-72 top-0 bottom-0 w-full">
                <ItemImg className={'absolute left-[60%] bottom-0 w-[60%] animated-scroll'} img={extensionsList[3].img_1} />

                <ItemImg className={'absolute left-[10%] bottom-0 w-[78%] animated-scroll'} img={extensionsList[3].img_2} />
              </div>
            </div>

            <h2 className='absolute right-0 top-28 w-80 text-3xl font-bold animated-scroll nax-w-sm'>{extensionsList[3].text}</h2>
          </li>

          <li ref={refAnim2} className='flex flex-none h-full pt-20 2xtall:pt-[132px] w-[75vw] max-w-[1900px]'>
            <div className="flex-none relative h-full ml-40 aspect-[0.94/1]">
              <div className="absolute left-0 top-0 bottom-0 w-full">
                <ItemImg className={'absolute left-0 bottom-[20%] 2xl:bottom-54 w-[92%] animated-scroll'} img={extensionsList[1].img_1} />

                <ItemImg className={'absolute left-[63%] bottom-20 w-[56%] animated-scroll'} img={extensionsList[1].img_2} />
              </div>
            </div>

            <h2 className='mt-44 mr-40 text-3xl font-bold animated-scroll max-w-sm'>{extensionsList[1].text}</h2>
          </li>

          <li ref={refAnim3} className='flex flex-none h-full pt-20 2xtall:pt-[132px] w-[75vw] max-w-[1900px]' >
            <div className="flex-none relative h-full ml-10 aspect-[1.06/1]">
              <div className="absolute left-0 top-0 bottom-0 w-full -mr-60">
                <ItemImg className={'absolute left-0 bottom-[35%] w-[47%] animated-scroll'} img={extensionsList[2].img_1} />

                <ItemImg className={'absolute left-[40%] bottom-[20%] w-[47%] animated-scroll'} img={extensionsList[2].img_2} />
              </div>
            </div>

            <h2 className='mt-2 -ml-[340px] text-3xl font-bold animated-scroll max-w-sm'>{extensionsList[2].text}</h2>
          </li>
        </ul>
      </div>
    </div>
  </div>
}

export default Desktop;