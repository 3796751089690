import React, { useEffect, useState, useRef } from 'react';

const ItemSlideVideo = ({ slide, isPaused, setDuration }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const video = useRef(null);

  useEffect(() => {
    if (isPaused) {
      video.current.pause();
    } else {
      video.current.play();
    }
  }, [isPaused]);

  useEffect(() => {
    const videoElement = video.current;

    if (!videoElement) return;

    const onLoadedMetadata = (e) => {
      if (!videoElement.duration || isLoaded) return;

      setIsLoaded(true);

      setDuration(videoElement.duration);
    };

    onLoadedMetadata();

    videoElement.addEventListener('loadedmetadata', onLoadedMetadata);

    return () => {
      videoElement.removeEventListener('loadedmetadata', onLoadedMetadata);
    }
  }, [video]);

  useEffect(() => {
    if (!isLoaded) return;

    if (isLoaded && !isPaused) {
      video.current.play();
    }
  }, [isLoaded, isPaused]);

  return (
    <div>
      <div className="relative pt-20">
        <div className="flex items-center min-h-[96px] mb-6">
          <div className="text-center text-[32px] sm:text-5xl leading-tight font-bold 2xl:max-w-4xl mx-auto">{slide.title}</div>
        </div>
        <div className="-mx-container">
          <video
            ref={video}
            muted
            playsInline
            className="h-[400px] sm:h-[600px] w-full sm:w-auto object-contain sm:object-cover 2xl:object-contain object-bottom mx-auto"
            src={slide.video.src}
          />
        </div>
      </div>
    </div>
  );
};

export default ItemSlideVideo;