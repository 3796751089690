import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';
import classname from 'classnames';

let timer = null;

const VideoModal = ({ video, onClose }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setFullscreen] = useState(false);

  const [showControlls, setShowControlls] = useState(false);

  const player = useRef(null);
  const container = useRef(null);

  const onClickFullscreen = useCallback(() => {
    const el = container.current;

    if (!el) return;

    if (!isFullscreen) {
      openFullscreen(el);
    } else {
      closeFullscreen();
    }
  }, [isFullscreen, container]);

  const moveTo = (e) => {
    player.current.seekTo(Number(e.target.value), 'seconds');
  }

  const updateProgress = (e) => {
    setProgress(e.playedSeconds);
  };

  const openFullscreen = (container) => {
    if (container.requestFullscreen) {
      container.requestFullscreen();
    } else if (container.mozRequestFullScreen) { /* Firefox */
      container.mozRequestFullScreen();
    } else if (container.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      container.webkitRequestFullscreen();
    } else if (container.webkitRequestFullScreen) { /* Chrome, Safari and Opera */
      container.webkitRequestFullScreen();
    }
    else if (container.msRequestFullscreen) { /* IE/Edge */
      container.msRequestFullscreen();
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.webkitExitFullScreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullScreen();
    }
    else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  };

  const clickOutside = useCallback((e) => {
    if (e.target && container.current.contains(e.target)) return;

    onClose();
  }, [container.current]);

  useEffect(() => {
    const closeByEsc = (e) => {
      if (e.key !== 'Escape') return;

      onClose();
    };

    if (isFullscreen) {
      window.removeEventListener('keydown', closeByEsc);
      document.removeEventListener('click', clickOutside);
    } else {
      window.addEventListener('keydown', closeByEsc);
      document.addEventListener('click', clickOutside);
    }

    return () => {
      window.removeEventListener('keydown', closeByEsc);
      document.removeEventListener('click', clickOutside)
    }
  }, [clickOutside, isFullscreen]);

  const hideControlls = useCallback(() => {
    setShowControlls(false);

    if (timer) clearTimeout(timer);
  }, []);

  const handleShowControlls = useCallback(() => {
    setShowControlls(true);

    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      setShowControlls(false);
    }, 2000);
  }, []);

  const fullscreenchanged = useCallback((e) => {
    setFullscreen(!!document.fullscreenElement);
  }, []);

  useEffect(() => {
    const el = container.current;

    if (!el) return;

    el.addEventListener("fullscreenchange", fullscreenchanged);
    el.addEventListener("webkitfullscreenchange", fullscreenchanged);
    el.addEventListener("mozfullscreenchange", fullscreenchanged);
    el.addEventListener("MSFullscreenChange", fullscreenchanged);

    return () => {
      el.removeEventListener("fullscreenchange", fullscreenchanged);
      el.removeEventListener("webkitfullscreenchange", fullscreenchanged);
      el.removeEventListener("mozfullscreenchange", fullscreenchanged);
      el.removeEventListener("MSFullscreenChange", fullscreenchanged);
    }
  }, [container.current]);

  return (
    <div className='fixed inset-0 z-[9999] flex items-center justify-center sm:p-20 bg-black/50'>
      <div className={classname("relative", isFullscreen && 'flex items-center justify-center')} ref={container}>
        {
          !isFullscreen &&
          <button
            type="button"
            className='flex items-center justify-center h-10 w-10 rounded-full bg-white hover:scale-110 transition-all absolute top-0 -right-12'
            onClick={onClose}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m8 8 8 8M16 8l-8 8" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>
          </button>
        }

        <div className={classname('relative bg-black/40 group flex items-center justify-center max-h-screen', isFullscreen ? 'mx-auto w-full' : 'overflow-hidden aspect-video sm:rounded-2xl w-[calc(100vw_-_120px)] 2xl:w-[1280px]')}
          onMouseMove={handleShowControlls}
          onMouseLeave={hideControlls}
        >
          <ReactPlayer
            ref={player}
            url={
              [
                // { src: video.src.webm, type: 'video/webm' },
                { src: video.src.mp4, type: 'video/mp4' }
              ]}
            width='100%'
            height='100%'
            playing={isPlaying}
            onDuration={setDuration}
            progressInterval={20}
            loop
            autoPlay
            playsInline
            muted={isMuted}
            onProgress={updateProgress}
            className='h-full object-contain object-center w-full'
          ></ReactPlayer>

          <div className={classname("absolute inset-0 opacity-0 pointer-events-none transition-all text-white bg-black/40", showControlls && 'opacity-100 pointer-events-auto')}>

            <button
              type="button"
              className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:scale-110 transition-all'
              onClick={() => setIsPlaying(state => !state)}>
              {
                isPlaying ?
                  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M98 56c0 23.198-18.802 42-42 42S14 79.198 14 56s18.802-42 42-42 42 18.802 42 42ZM65.334 38a4 4 0 0 1 4 4v28a4 4 0 0 1-8 0V42a4 4 0 0 1 4-4Zm-14.668 4a4 4 0 0 0-8 0v28a4 4 0 0 0 8 0V42Z" fill="#fff" /></svg> :
                  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M56 98c23.198 0 42-18.802 42-42S79.198 14 56 14 14 32.802 14 56s18.802 42 42 42Zm13.174-45.01L51.058 42.273c-2.333-1.38-5.282.304-5.282 3.015v21.425c0 2.711 2.949 4.396 5.282 3.014l18.116-10.714c2.292-1.354 2.292-4.672 0-6.025Z" fill="#fff" /></svg>
              }
            </button>


            <div className="absolute bottom-10 left-4 right-4">
              <div className="flex items-center gap-4 sm:w-fit mx-auto">
                <button
                  type="click"
                  onClick={() => setIsMuted(state => !state)}
                  className='hover:scale-110 transition-all'
                >
                  {
                    isMuted ?
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m5.875 8.626 3.979-3.371a1 1 0 0 1 1.646.763v11.965c0 .852-.997 1.314-1.646.763l-3.979-3.371" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M5.875 15.375H3.5a1 1 0 0 1-1-1v-4.75a1 1 0 0 1 1-1h2.375M20.58 9.69l-4.5 4.5M20.58 14.19l-4.5-4.5" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg> :
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M5.875 8.626H3.5a1 1 0 0 0-1 1v4.75a1 1 0 0 0 1 1h2.375l3.979 3.37a1 1 0 0 0 1.646-.762V6.018c0-.852-.997-1.314-1.646-.763l-3.979 3.37Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /><path d="M15.537 15.977c1.162-.722 1.963-2.223 1.963-3.973 0-1.748-.8-3.252-1.963-3.983M18.505 19.064c1.808-1.555 2.995-4.134 2.995-7.062 0-2.93-1.189-5.512-3-7.066" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>
                  }
                </button>

                <div className="relative w-full sm:w-[500px]">
                  <input
                    type="range"
                    className='block w-full h-2 overflow-hidden rounded-lg bg-[#A4A4A4] appearance-none cursor-pointer [&::-webkit-slider-thumb]:opacity-0'
                    onChange={moveTo}
                    onMouseDown={() => setIsPlaying(false)}
                    onMouseUp={() => setIsPlaying(true)}
                    min={0}
                    step={0.02}
                    max={duration}
                  />
                  <span
                    className='pointer-events-none absolute left-0 top-0 bottom-0 rounded-lg bg-white'
                    style={{ width: `${progress / duration * 100}%` }}
                  ></span>
                </div>

                <button
                  type="button"
                  onClick={onClickFullscreen}
                  className='hover:scale-110 transition-all'
                >
                  {
                    isFullscreen ?
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 9h-4V5M21 3l-6 6M5 15h4v4M3 21l6-6M15 19v-4h4M21 21l-6-6M9 5v4H5M3 3l6 6" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg> :
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 3h4v4M15 9l6-6M7 21H3v-4M9 15l-6 6M21 17v4h-4M15 15l6 6M3 7V3h4M9 9 3 3" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;