import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useIntl } from "gatsby-plugin-intl";

import useWindowSize from '@/hooks/useWindowResize';

import banner from '@/images/banner.png';

const Banner = ({ className }) => {
  const { width } = useWindowSize();
  const image = useRef(null);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (width <= 768 && image.current) {
      const scrollableAmount = image.current.scrollWidth - image.current.clientWidth;

      image.current.scrollLeft = scrollableAmount * 0.35;
    }
  }, [width, image]);

  return <section className={classnames(className, 'pb-10 sm:py-16 bg-white portrait:h-[130vh] xl:h-screen')}>
    <div className="relative portrait:h-full landscape:md:h-full flex flex-col">
      <div className="relative xl:absolute xl:inset-0 xl:pointer-events-none -bottom-24">
        <div className="fluid-container sm:h-full">
          <div className="sm:flex sm:flex-col sm:h-full">
            <h2 className='mb-6 sm:mb-0 text-6xl lg:text-8xl font-bold'>Souls.club<br />{formatMessage({ id: 'ecosystem' })}</h2>

            <div className="sm:mt-auto sm:ml-auto xl:max-w-md xl:w-full">
              <div className="text-2xl lg:text-3xl font-bold sm:pb-20">
                <p>{formatMessage({ id: 'Interconnected projects within the club: Digital Animals, Digital Souls, the Game, cyber security SafeSoul, Souls shop' })}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex sm:block portrait:h-full text-center w-full overflow-x-auto overflow-y-visible scrollbar-hide" ref={image}>
        <img src={banner} className="inline-block h-full w-auto object-contain max-w-none lg:max-w-full landscape:h-[500px] landscape:md:h-screen landscape:xl:h-full" />
      </div>
    </div>
  </section >
};

export default Banner;