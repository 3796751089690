import React, { useRef, useEffect, useCallback } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import StaticImg from '@/components/common/StaticImg.jsx';
import RolesSections from './RolesSections';

const Mobile = ({ rowsTable, headersTabel }) => {
  const rolesList = useRef(null);
  const container = useRef(null);

  const { formatMessage } = useIntl();

  const handleScroll = useCallback(() => {
    const elem = rolesList.current;
    const wrapper = container.current;

    if (!elem || !wrapper) return;

    const containerBottom = window.innerHeight - elem.getBoundingClientRect().bottom;

    const wrapperBottom = window.innerHeight - wrapper.getBoundingClientRect().bottom;

    if (containerBottom >= 0 && wrapperBottom <= 0) {
      elem.style.height = elem.offsetHeight + 'px';
      elem.children[0].classList.add('fixed');
      elem.children[0].classList.add('bottom-0');
      elem.children[0].classList.add('left-0');
      elem.children[0].classList.add('px-container');
      elem.children[0].classList.add('w-full');
    } else {
      elem.style.height = '';
      elem.children[0].classList.remove('fixed');
      elem.children[0].classList.remove('bottom-0');
      elem.children[0].classList.remove('left-0');
      elem.children[0].classList.remove('px-container');
      elem.children[0].classList.remove('w-full');
    }
  }, [rolesList.current, container.current]);

  useEffect(() => {
    if (!rolesList.current || !container.current) return;

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    }
  }, [handleScroll, rolesList.current, container.current]);

  return (
    <div className="fluid-container" ref={container}>
      <div className="relative mb-5">
        <div className="" ref={rolesList}>
          <div className="z-0 pt-16">
            <h2 className="mb-6 text-6xl font-bold">{formatMessage({ id: 'Roles' })}
            </h2>

            <div className="pb-6">
              <RolesSections />
            </div>
          </div>
        </div>

        <div className="pt-10 pb-20 bg-body xl:bg-transparent relative z-1">

          <ul className='-mx-[16px]'>
            <li className='flex'>
              <div className='w-32'></div>
              {
                headersTabel.map((item, index) => (
                  <div className='flex-1 px-2 xl:px-2 sm:pb-4' key={index}>
                    <div className="text-center sm:text-left">
                      <p className="flex items-center justify-center sm:justify-start mb-1.5 sm:text-4xl font-medium sm:font-bold"><span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                        {
                          item.isSpecific ?
                            <span className='flex-none self-center sm:self-auto ml-1 sm:ml-2 h-4 w-4 sm:h-11 sm:w-11'>
                              <svg width="100%" height="100%" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.6304 0.405665C21.8287 0.187988 22.1713 0.187989 22.3696 0.405666L26.3393 4.76276C26.4709 4.90716 26.6745 4.96172 26.8606 4.90244L32.4771 3.11396C32.7577 3.02461 33.0543 3.19588 33.1173 3.48356L34.3766 9.24178C34.4183 9.43261 34.5674 9.58167 34.7582 9.62341L40.5164 10.8827C40.8041 10.9457 40.9754 11.2423 40.886 11.5229L39.0976 17.1394C39.0383 17.3255 39.0928 17.5291 39.2372 17.6607L43.5943 21.6304C43.812 21.8287 43.812 22.1713 43.5943 22.3696L39.2372 26.3393C39.0928 26.4709 39.0383 26.6745 39.0976 26.8606L40.886 32.4771C40.9754 32.7577 40.8041 33.0543 40.5164 33.1173L34.7582 34.3766C34.5674 34.4183 34.4183 34.5674 34.3766 34.7582L33.1173 40.5164C33.0543 40.8041 32.7577 40.9754 32.4771 40.886L26.8606 39.0976C26.6745 39.0383 26.4709 39.0928 26.3393 39.2372L22.3696 43.5943C22.1713 43.812 21.8287 43.812 21.6304 43.5943L17.6607 39.2372C17.5291 39.0928 17.3255 39.0383 17.1394 39.0976L11.5229 40.886C11.2423 40.9754 10.9457 40.8041 10.8827 40.5164L9.62341 34.7582C9.58167 34.5674 9.43261 34.4183 9.24178 34.3766L3.48356 33.1173C3.19588 33.0543 3.02461 32.7577 3.11396 32.4771L4.90244 26.8606C4.96172 26.6745 4.90716 26.4709 4.76276 26.3393L0.405665 22.3696C0.187988 22.1713 0.187989 21.8287 0.405666 21.6304L4.76276 17.6607C4.90716 17.5291 4.96172 17.3255 4.90244 17.1394L3.11396 11.5229C3.02461 11.2423 3.19588 10.9457 3.48356 10.8827L9.24178 9.62341C9.43261 9.58167 9.58167 9.43261 9.62341 9.24178L10.8827 3.48356C10.9457 3.19588 11.2423 3.02461 11.5229 3.11396L17.1394 4.90244C17.3255 4.96172 17.5291 4.90716 17.6607 4.76276L21.6304 0.405665Z" fill="#C2E900" />
                                <path d="M18.3332 27.0976L13.2365 22.0009L11.501 23.7243L18.3332 30.5565L32.9999 15.8898L31.2765 14.1665L18.3332 27.0976Z" fill="black" />
                              </svg>
                            </span>
                            : <></>
                        }
                      </p>
                      <p className="hidden sm:block text-black/50 text-sm font-medium max-w-2xs">{item.description}</p>
                    </div>
                  </div>
                ))
              }
            </li>

            {
              rowsTable.map((item, index) => (
                <li className='flex even:bg-[#ECECEC] rounded-2xl px-2 py-4 sm:p-0' key={index}>
                  <div className='self-center sm:select-auto flex-none w-32 sm:w-[280px] lg:w-[340px] sm:py-6 xl:p-6'>
                    <div className="">
                      <p className="mb-1 sm:mb-2 leading-tight sm:text-xl font-medium">{item.title}</p>
                      <p className="text-black/50 leading-tight text-[10px] sm:text-sm font-medium">{item.description}</p>
                    </div>
                  </div>

                  {
                    item.checked.map((check, i) => {
                      return <div className='flex-1 self-center px-2' key={i}>
                        {
                          check === 1 ?
                            <div className="m-auto sm:m-0 lg:px-10 w-fit">
                              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="40" rx="16" fill="#ECECEC" />
                                <path d="M17 24.17L12.83 20L11.41 21.41L17 27L29 15L27.59 13.59L17 24.17Z" fill="black" />
                              </svg>
                            </div>
                            : null
                        }
                      </div>
                    })
                  }
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Mobile;