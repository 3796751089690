import React, { useEffect, useRef, useCallback } from 'react';

const VideoMobile = ({ video, onClose }) => {
  const container = useRef(null);

  const openFullscreen = (container) => {
    if (container.requestFullscreen) {
      container.requestFullscreen();
    } else if (container.mozRequestFullScreen) { /* Firefox */
      container.mozRequestFullScreen();
    } else if (container.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      container.webkitRequestFullscreen();
    } else if (container.webkitRequestFullScreen) { /* Chrome, Safari and Opera */
      container.webkitRequestFullScreen();
    }
    else if (container.msRequestFullscreen) { /* IE/Edge */
      container.msRequestFullscreen();
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.webkitExitFullScreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullScreen();
    }
    else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    const video = container.current;

    if (video) {
      video.play();
      openFullscreen(video);
    }

    return () => {
      if (video) {
        video.pause();
      }
    };
  }, []);


  const fullscreenchanged = useCallback((e) => {
    if (!document.fullscreenElement) {
      onClose();
    }
  }, []);

  useEffect(() => {
    const el = container.current;

    if (!el) return;

    el.addEventListener("fullscreenchange", fullscreenchanged);
    el.addEventListener("webkitendfullscreen", fullscreenchanged);
    el.addEventListener("webkitfullscreenchange", fullscreenchanged);
    el.addEventListener("mozfullscreenchange", fullscreenchanged);
    el.addEventListener("MSFullscreenChange", fullscreenchanged);

    return () => {
      el.removeEventListener("fullscreenchange", fullscreenchanged);
      el.removeEventListener("webkitfullscreenchange", fullscreenchanged);
      el.removeEventListener("mozfullscreenchange", fullscreenchanged);
      el.removeEventListener("MSFullscreenChange", fullscreenchanged);
    }
  }, [container.current]);

  return (
    <video className="absolute top-0 left-0 right-0" src={video.src.mp4} ref={container}></video>
  );
};

export default VideoMobile;