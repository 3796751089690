import React from 'react';
import ItemSlideImage from './Image';
import ItemSlideVideo from './Video';

const ItemSlide = ({ slide, isPaused, setDuration }) => {
  if (!slide?.video)
    return <ItemSlideImage
      slide={slide}
      setDuration={setDuration}
    />

  return <ItemSlideVideo
    slide={slide}
    isPaused={isPaused}
    setDuration={setDuration}
  />
};

export default ItemSlide;