import React from 'react';
import StaticImg from '@/components/common/StaticImg.jsx';

const RolesSections = () => {
  return (
    <>
      <div className='self-center sm:select-auto flex-1 lg:flex-none lg:w-[340px] sm:px-2 sm:py-4 xl:pr-12 xl:pl-6'>
        <div className="sm:max-w-xs">
          <div className="mb-4 sm:mb-2 text-black text-xl font-medium">
            Status change
          </div>
          <div className="lg:h-[72px] mb-2 text-[13px] font-medium leading-snug">Official links are marked as verified. Communities have special access to the verification process to mark affiliated and non-affiliated project pages.</div>

          <div className="mb-2 flex items-center p-2 bg-[#407FDB]/20 rounded-md">
            <span className="mr-1.5"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="6" fill="#fff" /><path d="M16 8.38c0-1.203-.666-2.247-1.636-2.742.117-.331.181-.69.181-1.067 0-1.683-1.302-3.046-2.908-3.046-.359 0-.701.064-1.018.19C10.148.698 9.15 0 8 0S5.855.699 5.382 1.714a2.747 2.747 0 0 0-1.018-.19c-1.607 0-2.909 1.364-2.909 3.047 0 .377.063.735.18 1.067C.668 6.133 0 7.176 0 8.381c0 1.139.596 2.132 1.48 2.656-.016.13-.025.259-.025.392 0 1.683 1.302 3.047 2.91 3.047.357 0 .7-.065 1.016-.19C5.854 15.302 6.85 16 8 16c1.152 0 2.147-.698 2.619-1.714.316.124.659.189 1.018.189 1.607 0 2.909-1.364 2.909-3.048 0-.133-.01-.262-.026-.39.883-.524 1.48-1.517 1.48-2.655V8.38Zm-5.04-2.54-3.302 4.953a.57.57 0 0 1-.793.159l-.088-.072-1.84-1.84a.57.57 0 1 1 .808-.808L7.093 9.58l2.915-4.374a.572.572 0 0 1 .952.635Z" fill="#407FDB" /></svg></span>
            <span className='font-bold text-[13px] leading-none'>OpenSea verified</span>
          </div>

          <div className="mb-2 flex items-center p-2 rounded-md border border-[#474747]">
            <span className="block mr-1.5 h-4 w-4">
              <StaticImg path='doodles.png' alt='verified' className="h-4 w-4" />
            </span>
            <span className='font-bold text-[13px] leading-none'>Verified by Doodles admins</span>
          </div>
        </div>
      </div>

      <div className='mt-10 sm:mt-0 sm:flex-1 sm:px-2 sm:py-6 xl:py-6 xl:pr-12 xl:pl-2'>
        <div className="sm:max-w-[270px]">
          <div className='mb-0.5 sm:mb-2 sm:text-transparent text-3xl sm:text-xl font-bold'>Scout</div>
          <div className="sm:hidden mb-2 text-black/60 text-lg font-medium max-w-[260px] leading-snug">Any user who has web3 history.</div>
          <div className="max-w-[260px] sm:max-w-full mb-2 lg:h-[72px] text-[13px] font-medium text-black/50">Scout votes are registered and sent to Patrol for further check, security status doesn't change at this stage.</div>

          <div className="mb-2 flex items-center p-2 rounded-md bg-[#474747] text-white">
            <span className="block mr-1.5 h-4 w-4">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" rx="8" fill="#fff" /><path d="m13 8-2.707-1.089a2.143 2.143 0 0 1-1.189-1.187L8.008 3 6.91 5.724A2.14 2.14 0 0 1 5.723 6.91L3.016 8H3l2.707 1.089c.542.217.97.646 1.189 1.187L7.992 13l1.097-2.724a2.14 2.14 0 0 1 1.188-1.187L12.984 8" stroke="#323232" strokeLinecap="round" strokeLinejoin="round" /></svg>
            </span>

            <div className="flex items-center">
              <span className='font-bold text-[13px] leading-none'>Vote</span>

              <span className='flex ml-2 opacity-60 text-[11px] font-bold flex-shrink-0'>
                <span>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.91675 11.6668C2.91675 10.2084 4.08341 9.1001 5.48341 9.1001H8.45842C9.91675 9.1001 11.0251 10.2668 11.0251 11.6668" stroke="white" strokeWidth="1.4824" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.75012 3.03337C9.74178 4.02504 9.74178 5.60004 8.75012 6.53337C7.75845 7.46671 6.18345 7.52504 5.25012 6.53337C4.31678 5.54171 4.25845 3.96671 5.25012 3.03337C6.24178 2.10004 7.75845 2.10004 8.75012 3.03337" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                734
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-10 sm:mt-0 sm:flex-1 sm:px-2 sm:py-6 xl:py-6 xl:pr-12 xl:pl-2'>

        <div className="sm:max-w-[270px]">
          <div className='mb-0.5 sm:mb-2 sm:text-transparent text-3xl sm:text-xl font-bold'>Patrol</div>
          <div className="sm:hidden mb-2 text-black/60 text-lg font-medium max-w-[260px] leading-snug">Scout with our bound-token.</div>
          <div className="max-w-[260px] sm:max-w-full mb-2 lg:h-[72px] text-[13px] font-medium text-black/50">Patrol votes are registered, status is changed to Suspicious and badge color to yellow, alert is sent to OG Patrol.</div>

          <div className="mb-2 flex items-center p-2 rounded-md bg-[#FF7138] text-white">
            <span className="block mr-1.5 h-4 w-4">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.786 6.27C4.936 2.258 6.011.252 7.581.03c.278-.04.56-.04.838 0 1.57.222 2.645 2.228 4.795 6.24 2.15 4.012 3.224 6.018 2.618 7.594a3.53 3.53 0 0 1-.419.782C14.45 16 12.3 16 8 16c-4.3 0-6.45 0-7.413-1.354a3.533 3.533 0 0 1-.42-.782c-.605-1.576.47-3.582 2.62-7.594Z" fill="#fff" /><path d="M8.772 4.667H7.228V6.83l.404 3.416h.736l.404-3.438V4.667Zm.03 6.302H7.198v1.725h1.606v-1.725Z" fill="#FF7138" /></svg>
            </span>

            <div className="flex items-center">
              <span className='font-bold text-[13px] leading-none'>Suspicious</span>

              <span className='flex ml-2 opacity-60 text-[11px] font-bold flex-shrink-0'>
                <span>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.91675 11.6668C2.91675 10.2084 4.08341 9.1001 5.48341 9.1001H8.45842C9.91675 9.1001 11.0251 10.2668 11.0251 11.6668" stroke="white" strokeWidth="1.4824" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.75012 3.03337C9.74178 4.02504 9.74178 5.60004 8.75012 6.53337C7.75845 7.46671 6.18345 7.52504 5.25012 6.53337C4.31678 5.54171 4.25845 3.96671 5.25012 3.03337C6.24178 2.10004 7.75845 2.10004 8.75012 3.03337" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                734
              </span>
            </div>
          </div>
        </div>

      </div>

      <div className='mt-10 sm:mt-0 sm:flex-1 sm:px-2 sm:py-6 xl:py-6 xl:pr-12 xl:pl-2'>

        <div className="sm:max-w-[270px]">
          <div className='flex sm:block mb-0.5 sm:mb-2 sm:text-transparent text-3xl sm:text-xl font-bold'>
            Verified
            <span className='block sm:hidden sm:self-auto ml-1 sm:ml-2 h-7 w-7 sm:h-11 sm:w-11 '>
              <svg width="100%" height="100%" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.6304 0.405665C21.8287 0.187988 22.1713 0.187989 22.3696 0.405666L26.3393 4.76276C26.4709 4.90716 26.6745 4.96172 26.8606 4.90244L32.4771 3.11396C32.7577 3.02461 33.0543 3.19588 33.1173 3.48356L34.3766 9.24178C34.4183 9.43261 34.5674 9.58167 34.7582 9.62341L40.5164 10.8827C40.8041 10.9457 40.9754 11.2423 40.886 11.5229L39.0976 17.1394C39.0383 17.3255 39.0928 17.5291 39.2372 17.6607L43.5943 21.6304C43.812 21.8287 43.812 22.1713 43.5943 22.3696L39.2372 26.3393C39.0928 26.4709 39.0383 26.6745 39.0976 26.8606L40.886 32.4771C40.9754 32.7577 40.8041 33.0543 40.5164 33.1173L34.7582 34.3766C34.5674 34.4183 34.4183 34.5674 34.3766 34.7582L33.1173 40.5164C33.0543 40.8041 32.7577 40.9754 32.4771 40.886L26.8606 39.0976C26.6745 39.0383 26.4709 39.0928 26.3393 39.2372L22.3696 43.5943C22.1713 43.812 21.8287 43.812 21.6304 43.5943L17.6607 39.2372C17.5291 39.0928 17.3255 39.0383 17.1394 39.0976L11.5229 40.886C11.2423 40.9754 10.9457 40.8041 10.8827 40.5164L9.62341 34.7582C9.58167 34.5674 9.43261 34.4183 9.24178 34.3766L3.48356 33.1173C3.19588 33.0543 3.02461 32.7577 3.11396 32.4771L4.90244 26.8606C4.96172 26.6745 4.90716 26.4709 4.76276 26.3393L0.405665 22.3696C0.187988 22.1713 0.187989 21.8287 0.405666 21.6304L4.76276 17.6607C4.90716 17.5291 4.96172 17.3255 4.90244 17.1394L3.11396 11.5229C3.02461 11.2423 3.19588 10.9457 3.48356 10.8827L9.24178 9.62341C9.43261 9.58167 9.58167 9.43261 9.62341 9.24178L10.8827 3.48356C10.9457 3.19588 11.2423 3.02461 11.5229 3.11396L17.1394 4.90244C17.3255 4.96172 17.5291 4.90716 17.6607 4.76276L21.6304 0.405665Z" fill="#C2E900" />
                <path d="M18.3332 27.0976L13.2365 22.0009L11.501 23.7243L18.3332 30.5565L32.9999 15.8898L31.2765 14.1665L18.3332 27.0976Z" fill="black" />
              </svg>
            </span>
          </div>
          <div className="sm:hidden mb-2 text-black/60 text-lg font-medium max-w-[260px] leading-snug">Invites only. Most trusted patrol members who are responsible for final decision.</div>
          <div className="max-w-[260px] sm:max-w-full mb-2 lg:h-[72px] text-[13px] font-medium text-black/50">OG Patrol votes are registered, security status and badge color are changed accordingly to OG voting.</div>

          <div className="mb-2 flex items-center p-2 rounded-md bg-[#D62839] text-white">
            <span className="block mr-1.5 h-4 w-4">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.786 6.27C4.936 2.258 6.011.252 7.581.03c.278-.04.56-.04.838 0 1.57.222 2.645 2.228 4.795 6.24 2.15 4.012 3.224 6.018 2.618 7.594a3.53 3.53 0 0 1-.419.782C14.45 16 12.3 16 8 16c-4.3 0-6.45 0-7.413-1.354a3.533 3.533 0 0 1-.42-.782c-.605-1.576.47-3.582 2.62-7.594Z" fill="#fff" /><path d="M8.772 4.667H7.228V6.83l.404 3.416h.736l.404-3.438V4.667Zm.03 6.302H7.198v1.725h1.606v-1.725Z" fill="#FF7138" /></svg>
            </span>

            <div className="flex items-center">
              <span className='font-bold text-[13px] leading-none'>Scam / Hacked / Phishing</span>

              <span className='flex ml-2 opacity-60 text-[11px] font-bold flex-shrink-0'>
                <span>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.91675 11.6668C2.91675 10.2084 4.08341 9.1001 5.48341 9.1001H8.45842C9.91675 9.1001 11.0251 10.2668 11.0251 11.6668" stroke="white" strokeWidth="1.4824" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.75012 3.03337C9.74178 4.02504 9.74178 5.60004 8.75012 6.53337C7.75845 7.46671 6.18345 7.52504 5.25012 6.53337C4.31678 5.54171 4.25845 3.96671 5.25012 3.03337C6.24178 2.10004 7.75845 2.10004 8.75012 3.03337" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                734
              </span>
            </div>
          </div>

          <div className="mb-2 flex items-center p-2 rounded-md bg-[#C2E900]">
            <span className="block mr-1.5 h-4 w-4">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="6" fill="#C2E900" /><path d="M16 8.38c0-1.203-.666-2.247-1.636-2.742.117-.331.181-.69.181-1.067 0-1.683-1.302-3.046-2.908-3.046-.359 0-.701.064-1.018.19C10.148.698 9.15 0 8 0S5.855.699 5.382 1.714a2.747 2.747 0 0 0-1.018-.19c-1.607 0-2.909 1.364-2.909 3.047 0 .377.063.735.18 1.067C.668 6.133 0 7.176 0 8.381c0 1.139.596 2.132 1.48 2.656-.016.13-.025.259-.025.392 0 1.683 1.302 3.047 2.91 3.047.357 0 .7-.065 1.016-.19C5.854 15.302 6.85 16 8 16c1.152 0 2.147-.698 2.619-1.714.316.124.659.189 1.018.189 1.607 0 2.909-1.364 2.909-3.048 0-.133-.01-.262-.026-.39.883-.524 1.48-1.517 1.48-2.655V8.38Zm-5.04-2.54-3.302 4.953a.57.57 0 0 1-.793.159l-.088-.072-1.84-1.84a.57.57 0 1 1 .808-.808L7.093 9.58l2.915-4.374a.572.572 0 0 1 .952.635Z" fill="#000" /></svg>
            </span>

            <div className="flex items-center">
              <span className='font-bold text-[13px] leading-none'>Souls verified</span>

              <span className='flex ml-2 opacity-60 text-[11px] font-bold flex-shrink-0'>
                <span>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.91675 11.6668C2.91675 10.2084 4.08341 9.1001 5.48341 9.1001H8.45842C9.91675 9.1001 11.0251 10.2668 11.0251 11.6668" stroke="black" strokeWidth="1.4824" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.75012 3.03337C9.74178 4.02504 9.74178 5.60004 8.75012 6.53337C7.75845 7.46671 6.18345 7.52504 5.25012 6.53337C4.31678 5.54171 4.25845 3.96671 5.25012 3.03337C6.24178 2.10004 7.75845 2.10004 8.75012 3.03337" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                734
              </span>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default RolesSections;