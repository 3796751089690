import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useIntl } from "gatsby-plugin-intl";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import useWindowSize from '@/hooks/useWindowResize.js';
import StaticImg from '@/components/common/StaticImg.jsx';
import Button from '@/components/common/Button';

import { DISCORD_URL } from '@/constants/urls';

const WebPatrol = ({ id = null }) => {
  const { formatMessage } = useIntl();
  const refAnim = useRef(null);
  const { height, width } = useWindowSize();

  useEffect(() => {
    if (!height || !refAnim?.current?.offsetHeight || width <= 1536) return;

    gsap.to(refAnim.current,
      {
        y: `-${height}px`,
        scrollTrigger: {
          trigger: refAnim.current,
          start: 'top 160%',
          scrub: 1,
          end: `+=${refAnim.current.offsetHeight}`,
        },
        lazy: false
      },
    )
  }, [width, height, refAnim.current]);

  useEffect(() => {
    if (width <= 1536) {
      gsap.killTweensOf(refAnim.current);
    }
  }, [width]);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [height]);

  return <><div id={id} className="sm:absolute sm:-mt-[calc(100vh_-_140px)]"></div><section ref={refAnim} className="relative bg-body pt-32 sm:pt-60 z-100 pb-10 after:absolute after:-z-1 after:bg-body after:top-full after:left-0 after:right-0 after:h-[120vh]">

    <div className="fluid-container">
      <div className="bg-white rounded-2xl p-4 sm:p-8 -mx-[8px] overflow-hidden transform-gpu">
        <div className="flex sm:items-center">
          <div className='xl:pr-5'>
            <div className="pb-4">
              <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M35.273 25.91a5.432 5.432 0 0 0 5.436-5.456A5.432 5.432 0 0 0 35.273 15a5.447 5.447 0 0 0-5.455 5.454 5.447 5.447 0 0 0 5.455 5.455Zm-14.546 0a5.432 5.432 0 0 0 5.437-5.456A5.432 5.432 0 0 0 20.727 15a5.447 5.447 0 0 0-5.454 5.454 5.447 5.447 0 0 0 5.454 5.455Zm0 3.636C16.491 29.546 8 31.672 8 35.909v4.546h25.455v-4.546c0-4.236-8.491-6.363-12.728-6.363Zm14.546 0c-.528 0-1.128.036-1.764.09 2.11 1.528 3.582 3.582 3.582 6.273v4.546H48v-4.546c0-4.236-8.49-6.363-12.727-6.363ZM0 0h8.235v1.647H0V0Z" fill="#000" /><path d="M1.647 0v8.235H0V0h1.647ZM56 56h-8.235v-1.647H56V56Z" fill="#000" /><path d="M54.352 56v-8.236H56V56h-1.648ZM0 56v-8.236h1.647V56H0Z" fill="#000" /><path d="M0 54.353h8.235V56H0v-1.647ZM55.999 0v8.235h-1.647V0h1.647Z" fill="#000" /><path d="M55.999 1.647h-8.235V0h8.235v1.647Z" fill="#000" /></svg>
            </div>
            <h2 className="max-w-[100px] xl:max-w-none text-6xl xl:text-8xl font-bold pb-6">{formatMessage({ id: 'Web3 Patrol' })}</h2>
            <div className="space-y-1 xl:space-y-2 text-lg xl:text-2xl font-bold pb-6" dangerouslySetInnerHTML={{ __html: formatMessage({ id: "<p>Soul Patrol works autonomously and monitors emerging dangers in web3 space.</p><p>The main mission of the patrol is to instantly respond to dangers, to warn users about recent hacks and to prevent wrong transactions.</p><p>To join the Patrol user generates a bound token, that cannot be transferred.</p>" }) }}>
            </div>

            <div className="w-full xl:w-fit">
              <Button href={DISCORD_URL} className={classnames("flex items-center w-full sm:w-fit space-x-3 bg-[#F8F8F8] pt-4 pb-3.5 pl-5 pr-5 sm:pr-6 rounded-2xl text-lg sm:text-2xl font-bold leading-none")}>
                <span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z" fill="black" />
                  </svg>

                </span>

                <span>{formatMessage({ id: "Become a Soul Patrol" })}</span>
              </Button>
            </div>
          </div>
          <div className="flex-none w-0 sm:w-1/2 2xl:w-[680px] relative -z-1">
            <div className="absolute h-[280px] w-[280px] -top-20 xl:-top-[240px] -right-20 xl:right-[60px] sm:h-[480px] sm:w-[480px]">
              <video autoPlay loop muted playsInline className="object-cover w-full h-full">
                <source src="/assets/video/Web.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section ></>
};

export default WebPatrol;