import React from 'react';

const DesktopFAQ = ({ FAQList }) => {
  return <ul className='md:columns-2 xl:columns-3 gap-4 xl:gap-8'>
    {
      FAQList.map((item, index) => (
        <li className='pb-4 xl:pb-8 column-not-brake' key={index}>
          <div className="bg-white rounded-2xl p-6 ">
            <h3 className="mb-4 text-3xl font-bold">{item.title}</h3>

            {item.isSprecials ?
              <p><svg width="171" height="242" viewBox="0 0 171 242" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M47.2412 120.168C47.2412 116.173 47.2412 111.402 47.2412 105.854C47.463 100.084 47.9066 94.2036 48.572 88.2118C49.2374 82.2201 50.3463 76.3393 51.8988 70.5695C53.4514 64.5777 55.5584 59.2517 58.2198 54.5915C61.1031 49.9312 64.6517 46.1586 68.8658 43.2737C73.3016 40.3888 78.7354 38.9464 85.1673 38.9464C91.5992 38.9464 97.0331 40.3888 101.469 43.2737C105.905 46.1586 109.564 49.9312 112.447 54.5915C115.331 59.2517 117.438 64.5777 118.768 70.5695C120.321 76.3393 121.43 82.2201 122.095 88.2118C122.982 94.2036 123.426 100.084 123.426 105.854C123.648 111.402 123.759 116.173 123.759 120.168C123.759 126.825 123.537 134.925 123.093 144.468C122.65 153.788 121.208 162.887 118.768 171.763C116.551 180.418 112.78 187.852 107.457 194.066C102.356 200.28 94.9261 203.387 85.1673 203.387C75.6303 203.387 68.3113 200.28 63.2101 194.066C58.1089 187.852 54.3385 180.418 51.8988 171.763C49.6809 162.887 48.3502 153.788 47.9066 144.468C47.463 134.925 47.2412 126.825 47.2412 120.168ZM0 120.168C0 143.469 2.2179 162.998 6.6537 178.754C11.3113 194.288 17.5214 206.715 25.284 216.036C33.0467 225.356 42.0292 232.014 52.2315 236.008C62.6556 240.003 73.6342 242 85.1673 242C96.9222 242 107.901 240.003 118.103 236.008C128.527 232.014 137.621 225.356 145.383 216.036C153.368 206.715 159.578 194.288 164.014 178.754C168.671 162.998 171 143.469 171 120.168C171 97.5323 168.671 78.5585 164.014 63.2462C159.578 47.7121 153.368 35.2847 145.383 25.9642C137.621 16.6437 128.527 9.98625 118.103 5.99175C107.901 1.99725 96.9222 0 85.1673 0C73.6342 0 62.6556 1.99725 52.2315 5.99175C42.0292 9.98625 33.0467 16.6437 25.284 25.9642C17.5214 35.2847 11.3113 47.7121 6.6537 63.2462C2.2179 78.5585 0 97.5323 0 120.168Z" fill="#C2E900" />
              </svg>
              </p>
              :
              <div className="font-medium space-y-1" dangerouslySetInnerHTML={{ __html: item.desc }}></div>
            }
          </div>
        </li>
      ))
    }
  </ul>
};

export default DesktopFAQ;