import React, { useEffect, useState, useCallback } from 'react';
import classnames from 'classnames';
import { useIntl } from "gatsby-plugin-intl";

import client from '@/helpers/apiClient';

let timer = null;

const Statistics = () => {
  const { formatMessage } = useIntl();

  const STATISTICS = [
    {
      title: formatMessage({ id: 'Scam alerts shown' }),
      value: '28,795'
    },
    {
      title: formatMessage({ id: 'Projects in database' }),
      value: '43,692'
    },
    {
      title: formatMessage({ id: 'Active beta users' }),
      value: '648'
    },
    {
      title: formatMessage({ id: 'Patrol users' }),
      value: '648'
    },
    {
      title: formatMessage({ id: 'Received votes' }),
      value: '1,168'
    }
  ];

  const [statistic, setStatistic] = useState(STATISTICS);
  const [statisticLoaded, setStatisticLoaded] = useState(false);

  const getStatistics = useCallback(async () => {
    const { data } = await client.get('sitecheck-request-count');

    setStatistic(state => [
      {
        ...state[0],
        // value: data.sitecheck_scam_requests_count.toLocaleString('en-US')
      },
      {
        ...state[1],
        value: data.projects_count.toLocaleString('en-US')
      },
      {
        ...state[2],
        // value: data.connections_count.toLocaleString('en-US')
      },
      {
        ...state[3],
        // value: data.patrols_count ? data.patrols_count.toLocaleString('en-US') : 0
      },
      {
        ...state[4],
        value: data.votes_count.toLocaleString('en-US')
      },
    ]);
    // setStatisticLoaded(true);
  }, []);

  const getInitStatistics = useCallback(async () => {
    const { data } = await client.get('app-statistics?last_day=true');

    const result = data.indicators;

    setStatistic(state => [
      {
        ...state[0],
        value: result.total_shown_scam.toLocaleString('en-US')
      },
      {
        ...state[1],
        // value: result.total_projects.toLocaleString('en-US')
      },
      {
        ...state[2],
        value: result.active_user.toLocaleString('en-US')
      },
      {
        ...state[3],
        value: result.patrols_count.toLocaleString('en-US')
      },
      {
        ...state[4],
        // value: result.total_votes.toLocaleString('en-US')
      }
    ]);

  }, []);

  const initStatistic = useCallback(async () => {
    await Promise.all([getInitStatistics(), getStatistics()]);

    setStatisticLoaded(true);
  }, [getInitStatistics, getStatistics]);

  useEffect(() => {
    initStatistic();

    timer = setInterval(() => {
      getStatistics();
    }, 60000);

    return () => {
      clearInterval(timer);
    }
  }, []);

  return (
    <section className="mt-6">
      <div className="fluid-container">
        <div className="bg-white rounded-2xl p-8">

          <ul className={classnames('flex flex-wrap xl:justify-between sm:gap-20', !statisticLoaded && 'opacity-0')}>
            {
              statistic
                .map((item, index) => (
                  <li key={index} className='px-5 py-4 xl:p-0 w-1/2 xl:w-[200px]'>
                    <p className="text-xl sm:text-[32px] text-black/40 font-medium leading-none min-h-[64px]" dangerouslySetInnerHTML={{ __html: item.title }}></p>
                    <p className="mt-auto text-2xl sm:text-[32px] text-black font-bold">{item.value}</p>
                  </li>
                ))
            }
          </ul>

        </div>
      </div>
    </section>
  );
};

export default Statistics;