import React, { useMemo } from 'react';
import { useIntl } from "gatsby-plugin-intl";

import useWindowSize from '@/hooks/useWindowResize';

import StaticImg from '@/components/common/StaticImg';

const Overview = () => {
  const { width } = useWindowSize();
  const { formatMessage } = useIntl();

  const list = [
    {
      url: {
        desktop: 'overview/desktop/1.jpg',
        tablet: 'overview/tablet/1.jpg',
        mobile: 'overview/mobile/1.jpg',
      },
      title: formatMessage({ id: 'Yes. SafeSoul' }),
    },
    {
      url: {
        desktop: 'overview/desktop/2.jpg',
        tablet: 'overview/tablet/2.jpg',
        mobile: 'overview/mobile/2.jpg',
      },
      title: formatMessage({
        id: 'Web3 suffers from the number of scams'
      }),
    },
    {
      url: {
        desktop: 'overview/desktop/3.jpg',
        tablet: 'overview/tablet/3.jpg',
        mobile: 'overview/mobile/3.jpg',
      },
      title: formatMessage({
        id: 'Free safety'
      }),
    },
    {
      url: {
        desktop: 'overview/desktop/4.jpg',
        tablet: 'overview/tablet/4.jpg',
        mobile: 'overview/mobile/4.jpg',
      },
      title: formatMessage({
        id: 'Forecasts project statuses and simulates transaction results before any click.'
      }),
    },
    {
      url: {
        desktop: 'overview/desktop/5.jpg',
        tablet: 'overview/tablet/5.jpg',
        mobile: 'overview/mobile/5.jpg',
      },
      title: formatMessage({
        id: 'Self-regulated safety system driven by community'
      }),
      link: 'https://safesoul.club/'
    },
    {
      url: {
        desktop: 'overview/desktop/6.jpg',
        tablet: 'overview/tablet/6.jpg',
        mobile: 'overview/mobile/6.jpg',
      },
      title: formatMessage({
        id: 'We make your digital life safer'
      }),
    },
    {
      url: {
        desktop: 'overview/desktop/7.jpg',
        tablet: 'overview/tablet/7.jpg',
        mobile: 'overview/mobile/7.jpg',
      },
      title: formatMessage({
        id: 'Expert Patrol monitors security and makes money on it'
      }),
    },
    {
      url: {
        desktop: 'overview/desktop/8.jpg',
        tablet: 'overview/tablet/8.jpg',
        mobile: 'overview/mobile/8.jpg',
      },
      title: formatMessage({
        id: 'Statistics'
      }),
    },
    {
      url: {
        desktop: 'overview/desktop/9.jpg',
        tablet: 'overview/tablet/9.jpg',
        mobile: 'overview/mobile/9.jpg',
      },
      title: formatMessage({
        id: 'Yes, we can identify scams in google searches'
      }),
    },
    {
      url: {
        desktop: 'overview/desktop/10.jpg',
        tablet: 'overview/tablet/10.jpg',
        mobile: 'overview/mobile/10.jpg',
      },
      title: formatMessage({
        id: 'Right in your browser you will see realtime status of the page and security alerts'
      }),
    },
    {
      url: {
        desktop: 'overview/desktop/11.jpg',
        tablet: 'overview/tablet/11.jpg',
        mobile: 'overview/mobile/11.jpg',
      },
      title: formatMessage({
        id: 'Monitoring these platforms'
      }),
    },
    {
      url: {
        desktop: 'overview/desktop/12.jpg',
        tablet: 'overview/tablet/12.jpg',
        mobile: 'overview/mobile/12.jpg',
      },
      title: formatMessage({
        id: 'Browsers'
      }),
    },
  ];

  const device = width >= 768 ? (width < 1280 ? 'tablet' : 'desktop') : 'mobile';

  const outList = useMemo(() => {
    switch (device) {
      case 'desktop': return [
        [list[0], list[1], list[2], list[3]],
        [list[4], list[5], list[6]],
        [list[7], list[8]],
        [list[9], list[10], list[11]],
      ];

      case 'tablet': return [
        [list[0], list[1], list[9]],
        [list[4], list[5], list[6], list[2], list[3]],
        [list[7], list[8], list[10], list[11]],
      ];

      case 'mobile': return [
        [list[0], list[1], list[2], list[6], list[8], list[10]],
        [list[4], list[5], list[3], list[7], list[9], list[11]]
      ];

      default: return [];
    }
  }, [width, device]);

  return (
    <div className='py-16 sm:py-24 md::py-56'>
      <div className="fluid-container">
        <h2 className='mb-10 sm:mb-8 text-6xl sm:text-8xl font-bold'>{formatMessage({
          id: 'Overview'
        })}</h2>

        <ul className='flex gap-2 sm:gap-3'>
          {
            outList.map((list, index) => {
              return <li key={index} className='flex-1'>
                <ul className='grid gap-2 sm:gap-3'>{
                  list.map((item, i) => {
                    return <li key={`${i}-${index}`} className='bg-white rounded-xl overflow-hidden'>
                      {
                        item.link ?
                          <a href={item.link} target='_blank' rel="noreferrer" className='block hover:opacity-90 transition'>
                            <StaticImg
                              path={item.url[device]}
                              alt={item.title}
                            /></a> :
                          <div className=''>
                            <StaticImg
                              path={item.url[device]}
                              alt={item.title}
                            />
                          </div>
                      }
                    </li>
                  })
                }
                </ul>
              </li>
            })
          }
        </ul>
      </div>
    </div >
  );
};

export default Overview;