import React from 'react';
import classnames from 'classnames';

import { useIntl } from "gatsby-plugin-intl";

const Partners = ({ id = null }) => {
  const { formatMessage } = useIntl();

  const partners = [
    {
      logo: '/images/partners/1.png',
      title: formatMessage({
        id: 'partnersList.BoringSecurity.title'
      }),
      description: formatMessage({
        id: 'partnersList.BoringSecurity.text'
      })
    },
    {
      logo: '/images/partners/2.png',
      title: formatMessage({
        id: 'partnersList.Delegate.title'
      }),
      description: formatMessage({
        id: 'partnersList.Delegate.text'
      }),
    },
  ];

  return (
    <section id={id} className="mt-20 sm:mt-20 pt-10 pb-20 sm:pb-60">
      <div className="fluid-container">
        <h2 className='mb-4 sm:mb-4 text-6xl sm:text-8xl font-bold'>{formatMessage({ id: 'Partners' })}</h2>
        <div className="mb-4 space-y-1 sm:space-y-2 text-lg sm:text-3xl font-bold max-w-5xl">
          <p>{formatMessage({
            id: 'Central to our strategy is forging strategic partnerships with security organizations, blockchain developers, and web3 companies.'
          })}</p>
        </div>

        <ul className='sm:flex sm:flex-wrap sm:-mx-2 lg:-mx-5 sm:w-auto'>
          {
            partners.map(({ logo, title, description }, index) =>
              <li key={index} className={classnames('pb-2 sm:p-2 lg:p-5', 'sm:w-1/2 lg:w-1/3')}>
                <div className="p-8 bg-white rounded-2xl h-full">
                  <div className="flex justify-center items-center h-28 w-fit pb-6">
                    <img src={logo} alt="" className='max-h-full' />
                  </div>

                  <h3 className='text-[32px] font-bold mb-2'>{title}</h3>

                  <div className="text-[#717171] font-medium" dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>
              </li>
            )
          }
        </ul>
      </div>
    </section>
  );
};

export default Partners;