import React from 'react';

const ButtonPause = ({ isPaused, setIsPaused }) => {
  return (
    <button type="button" className="flex items-center justify-center rounded-2xl bg-body-dark/50 backdrop-blur-md aspect-square transition-all hover:bg-body-dark/80" onClick={setIsPaused}>
      {isPaused ?
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 21a9 9 0 0 0 9-9 9 9 0 0 0-9-9 9 9 0 0 0-9 9 9 9 0 0 0 9 9Zm2.823-9.645-3.882-2.296a.75.75 0 0 0-1.132.646v4.59a.75.75 0 0 0 1.132.647l3.882-2.296a.75.75 0 0 0 0-1.291Z" fill="#fff" />
          </svg>
        </span> :
        <span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16 18a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 3 0v9A1.5 1.5 0 0 1 16 18ZM8 18a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 3 0v9A1.5 1.5 0 0 1 8 18Z" fill="#fff" />
          </svg>
        </span>
      }
    </button>
  );
};

export default ButtonPause;