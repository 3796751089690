import React, { useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import { useIntl } from "gatsby-plugin-intl";

import Progress from './Progress';
import ButtonPause from './ButtonPause';

import ItemSlide from './ItemSlide';

import bg from '/static/images/hero/bg.webp';
import ButtonShowVideo from './ButtonShowVideo';
import VideoModal from './VideoModal';
import VideoMobile from './VideoMobile';

const Hero = (className) => {
  const { formatMessage } = useIntl();

  const fullVideo = {
    src: {
      mp4: '/assets/video/safesoul-full.mp4',
      webm: '/assets/video/safesoul-full.webm',
    },
    poster: '/assets/video/safesoul-full.jpg',
  }

  const sliders = [
    {
      title: formatMessage({
        id: 'Community driven, self regulated safety platform'
      }),
      images: {
        desktop: {
          png: require('@/images/home/hero/slide-1--desktop.png').default,
          webp: require('@/images/home/hero/slide-1--desktop.webp').default,
        },
        mobile: {
          png: require('@/images/home/hero/slide-1--mobile.png').default,
          webp: require('@/images/home/hero/slide-1--mobile.webp').default,
        }
      }
    },
    {
      title: formatMessage({
        id: 'Browse with Confidence: Real-Time Scam Detection in Search'
      }),
      images: {
        desktop: {
          png: require('@/images/home/hero/slide-2--desktop.png').default,
          webp: require('@/images/home/hero/slide-2--desktop.webp').default,
        },
        mobile: {
          png: require('@/images/home/hero/slide-2--mobile.png').default,
          webp: require('@/images/home/hero/slide-2--mobile.webp').default,
        }
      }
    },
    {
      title: formatMessage({
        id: 'Up-to-Date Security Status on NFT Marketplaces'
      }),
      images: {
        desktop: {
          png: require('@/images/home/hero/slide-3--desktop.png').default,
          webp: require('@/images/home/hero/slide-3--desktop.webp').default,
        },
        mobile: {
          png: require('@/images/home/hero/slide-3--mobile.png').default,
          webp: require('@/images/home/hero/slide-3--mobile.webp').default,
        }
      }
    },
    {
      title: formatMessage({
        id: 'Distinguish legit amidst the fakes, get ready for more secure platforms to join'
      }),
      images: {
        desktop: {
          png: require('@/images/home/hero/slide-4--desktop.png').default,
          webp: require('@/images/home/hero/slide-4--desktop.webp').default,
        },
        mobile: {
          png: require('@/images/home/hero/slide-4--mobile.png').default,
          webp: require('@/images/home/hero/slide-4--mobile.webp').default,
        }
      }
    }
  ];

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [videoInModal, setVideoInModal] = useState(false);
  const [startProgressAnimation, setStartProgressAnimation] = useState(true);
  const [duration, setDuration] = useState(0);

  const activeSlide = useMemo(() => {
    return sliders[activeSlideIndex];
  }, [activeSlideIndex]);

  const switchToSpecificSlide = async (index = null) => {
    setStartProgressAnimation(false);

    setActiveSlideIndex(state => {
      const value = index ?? state + 1;

      if (value >= sliders.length) return 0;

      return value
    });
  };

  useEffect(() => {
    setIsPaused(videoInModal);
  }, [videoInModal]);

  return <section
    className={classnames(className, 'overflow-hidden bg-[url:var(--bg)] bg-center bg-no-repeat bg-cover')} style={{ '--bg': `url(${bg})` }}
  >
    <Helmet>
      {
        sliders.map((item, index) => (
          <link
            rel="preload"
            as="image"
            href={item.images.desktop.webp} key={index}
            media="(min-width: 768px)"
          />
        ))
      }

      {
        sliders.map((item, index) => (
          <link
            rel="preload"
            as="image"
            href={item.images.mobile.webp} key={index}
            media="(max-width: 768px)"
          />
        ))
      }
      <link rel="preload" href={fullVideo.src.mp4} as="video" type="video/mp4" />
    </Helmet>

    <div className="fluid-container">
      <div className="relative flex items-end justify-center w-full">

        <SwitchTransition mode="out-in">
          <CSSTransition
            key={activeSlideIndex}
            classNames="card-transition"
            timeout={300}
            onEntered={() => {
              setStartProgressAnimation(true);
            }}
          >
            <ItemSlide
              slide={activeSlide}
              key={activeSlideIndex}
              isPaused={isPaused}
              setDuration={setDuration}
            />
          </CSSTransition>
        </SwitchTransition>

        <div className="absolute bottom-4 sm:bottom-6 left-1/2 -translate-x-1/2 px-2">
          <div className="flex justify-center gap-1 sm:gap-3 h-14">
            <div className="flex justify-center items-center rounded-2xl bg-body-dark/50 backdrop-blur-md px-3 sm:px-6 gap-3">
              {
                sliders.map(
                  (item, index) =>
                    <div
                      className={classnames(
                        "relative h-2 rounded-full overflow-hidden transition-[min-width] ease-linear duration-300 hover:scale-110 hover:bg-white cursor-pointer",
                        activeSlideIndex === index ? 'min-w-[56px] pointer-events-none' : 'min-w-[8px]',
                        activeSlideIndex > index ? 'bg-white' : 'bg-[#ACACAC]',
                      )}
                      style={{ willChange: 'min-width', }}
                      key={index}
                      onClick={() => switchToSpecificSlide(index)}
                    >
                      {
                        (activeSlideIndex === index && duration !== 0) &&
                        <Progress
                          isPaused={!startProgressAnimation || isPaused}
                          onEnd={() => switchToSpecificSlide()}
                          duration={duration}
                          key={activeSlideIndex}
                        />
                      }
                    </div>
                )
              }
            </div>

            <ButtonPause isPaused={isPaused} setIsPaused={() => setIsPaused(state => !state)} />

            <ButtonShowVideo openVideo={() => setVideoInModal(true)} />
          </div>
        </div>
      </div>
    </div>

    <video
      src={fullVideo.src.mp4}
      preload='auto'
      className="hidden"
    />

    {
      videoInModal && (
        window.innerWidth > 768 ?
          <VideoModal
            video={fullVideo}
            onClose={() => setVideoInModal(false)}
          /> :
          <VideoMobile
            video={fullVideo}
            onClose={() => setVideoInModal(false)}
          />
      )
    }
  </section>
};

export default Hero;