import React, { useEffect, useState, useCallback } from "react";

import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import HeadSection from '@/components/common/Head';

import Header from '@/components/Header';
import Footer from '@/components/Footer';

import Hero from '@/sections/home/Hero';
import Banner from "@/sections/home/Banner";

import Section from '@/components/common/Section';
import sections from '@/constants/sections';

gsap.registerPlugin(ScrollTrigger);

const IndexPage = () => {
  useEffect(() => {
    // update scroll trigger on resize
    const updateScrollTrigger = () => {
      ScrollTrigger.refresh();
    };

    window.addEventListener('resize', updateScrollTrigger);

    return () => {
      window.removeEventListener('resize', updateScrollTrigger);
    };
  }, []);

  return (
    <div>
      <Header />

      <main>

        <Hero />
        {
          sections.map(({ to, component, title }) => (
            <Section
              key={to || title}
              id={to || title}
              Component={component}
            />
          ))
        }

        <Banner />

      </main>

      <Footer />
    </div>
  )
}

export default IndexPage

export const Head = () => <HeadSection />;