import React from 'react';

const Progress = ({ duration, isPaused = true, onEnd }) => {
  return <div
    className="absolute left-0 top-0 h-full w-0 bg-white rounded-full animate-progress"
    style={{
      willChange: 'width',
      '--duration': `${duration}s`,
      animationPlayState: isPaused ? 'paused' : 'running'
    }}
    onAnimationEnd={onEnd}
  ></div>
};

export default Progress;