import ScamNear from '@/sections/home/ScamNear';
import Extension from "@/sections/home/Extension";
import HowWorks from "@/sections/home/HowWorks";
import Roles from "@/sections/home/Roles";
import Faq from '@/sections/home/Faq';
import Community from '@/sections/home/Community';
import WebPatrol from '@/sections/home/WebPatrol';
import Token from '@/sections/home/Token';
import Partners from '@/sections/home/Partners';
import Team from '@/sections/home/Team';
import WeTrusted from '@/sections/home/WeTrusted';
import Overview from '@/sections/home/Overview';
import Statistics from '@/sections/home/Statistics';

const sections = [
  {
    title: 'Statistics',
    component: Statistics
  },
  {
    to: 'scam-is-near',
    title: 'Scam is near',
    component: ScamNear
  },
  {
    title: 'How It Work',
    component: HowWorks
  },
  {
    to: 'extension',
    title: 'Extension',
    component: Extension
  },
  {
    to: 'web3patrol',
    title: 'Web patrol',
    component: WebPatrol
  },
  {
    to: 'roles',
    title: 'Roles',
    component: Roles
  },
  {
    to: "achievements",
    title: 'Community',
    component: Community
  },
  {
    to: 'team',
    title: 'team',
    component: Team
  },
  {
    title: 'Token',
    component: Token
  },
  {
    to: 'trusted',
    title: 'Wearetrusted',
    component: WeTrusted
  },
  {
    to: 'faq',
    title: 'FAQ',
    component: Faq
  },
  {
    to: 'partners',
    title: 'Partners',
    component: Partners
  },
  {
    to: 'overview',
    title: 'Overview',
    component: Overview
  }
];

export default sections;