import React from 'react';

import useWindowSize from '@/hooks/useWindowResize.js';

import { useIntl } from "gatsby-plugin-intl";

import Desktop from './Desktop';
import Mobile from './Mobile';

const HowWorks = ({ id = null }) => {
  const { formatMessage } = useIntl();

  const { width } = useWindowSize();

  const data = {
    title: formatMessage({ id: 'SafeSoul Extension' }),
    text: formatMessage({ id: `Free browser extension integrated into Twitter alerts you to scams and suspicious activity in your feed. Immediate safety check for any open page: project on OS, scammed website etc.` }),
    img: 'how-work/cards.png'
  }

  return <section id={id} className="mt-20 xl:mt-60 pt-12 sm:overflow-hidden">
    {
      width > 769
        ? <Desktop data={data} />
        : <Mobile data={data} />
    }
  </section>
};

export default HowWorks;