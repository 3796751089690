import React from 'react';
import classnames from 'classnames';

import StaticImg from '@/components/common/StaticImg.jsx';

const ItemImg = ({ className, img, ...props }) => {
  return (
    <div
      className={classnames(className, "item-extension-img")}
      role="presentation"
    >
      <div
      >
        <div className="">
          <StaticImg path={img} className="w-full" {...props} />
        </div>
      </div>

    </div >
  )
};

export default ItemImg;