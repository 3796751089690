import React, { useEffect } from 'react';

const TIME_TO_SLIDE = 6;

const ItemSlideImage = ({ slide, setDuration }) => {
  useEffect(() => {
    setDuration(TIME_TO_SLIDE);
  }, []);

  return (
    <div>
      <div className="relative pt-20">
        <div className="flex items-center min-h-[96px] mb-6">
          <div className="text-center text-[32px] sm:text-5xl leading-tight font-bold 2xl:max-w-4xl mx-auto">{slide.title}</div>
        </div>
        <div className="-mx-container">
          <picture>
            <source
              srcSet={slide.images.mobile.webp}
              media="(max-width: 768px)"
              type="image/webp"
            />
            <source
              srcSet={slide.images.mobile.png}
              media="(max-width: 768px)"
              type="image/png"
            />
            <source
              srcSet={slide.images.desktop.webp}
              media="(min-width: 768px)"
              type="image/webp"
            />
            <source
              srcSet={slide.images.desktop.png}
              media="(min-width: 768px)"
              type="image/png"
            />
            <img
              src={slide.images.desktop.png}
              alt=""
              className='h-[400px] sm:h-[600px] w-full sm:w-auto object-contain sm:object-cover 2xl:object-contain object-bottom mx-auto' />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default ItemSlideImage;